import { Badge, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { Datetime, Validation, diffKey } from '@/utils';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { EventObject, OnChange } from '@/hooks/useOnChange';
import { Stop, Trip, TripKindEnum, TripStatusEnum, TripTypeEnum } from '@/models/gen/graphql';
import { TripStop, TripStopTimeSchedule } from '@/components/EditTripsModal/TripStop';

import DateSelector from '@/components/DateSelector';
import DragAndDrop from '@/components/DragAndDrop';
import DynamicContent from '@/components/DynamicContent';
import FormContainer from '@/components/FormContainer';
import FormField from '@/components/FormField';
import { LoadingBlur } from '@/components/LoadingSpinner';
import SelectAirlineIata from '@/components/SelectAirlineIata';
import SelectAirport from '@/components/SelectAirport';
import SelectClient from '@/components/SelectClient';
import SelectFlag from '@/components/SelectFlag';
import SelectKind from '@/components/SelectKind';
import SelectStatus from '@/components/SelectStatus';
import SelectType from '@/components/SelectType';
import { getTripForModal } from '@/api/services/trips/searchTrips';
import { updateTripValidator } from '@/queries/trips/updateTripBulk';
import { useAppState } from '@/store/appReducer';
import useTrips from '@/hooks/useTrips';

const keys = [
  'airportCode',
  'payerProviderId',
  'providerId',
  'servicerIataAirlineCode',
  'puLocationId',
  'doLocationId',
  'scheduled',
  'scheduledDays',
  'flightNumber',
  'attendants',
  'pilots',
  'type',
  'kind',
  'status',
  'crewId',
  'firstName',
  'lastName',
  'stops',
];
const getValidityType = (isValid: undefined | boolean, diff: boolean = false): Validation.ValidityType => {
  if (isValid === undefined) return Validation.ValidityType.INVARIANT;
  if (diff) return isValid ? Validation.ValidityType.VALID_WARNING : Validation.ValidityType.INVALID_WARNING;
  return isValid ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID;
};
const validator = (trip: any, upsProviderId: string, trips: any[] = []): any => {
  const validateKey = (key: string, val: any): any => {
    const isDiff = diffKey(key, [trip, ...Object.values(trips)]).length > 1;
    let isValid = undefined;
    const message = '';
    switch (key) {
      case 'airportCode': {
        isValid = !!val;
        break;
      }
      case 'payerProviderId': {
        isValid = !!val;
        break;
      }
      case 'providerId': {
        isValid = !!val;
        break;
      }
      case 'servicerIataAirlineCode': {
        isValid = !!val;
        break;
      }
      case 'puLocationId': {
        isValid = !!val;
        break;
      }
      case 'doLocationId': {
        isValid = !!val;
        break;
      }
      case 'scheduled': {
        isValid = !!trip?.scheduledDays?.length || !!new Datetime(val || '').asDayjs().isValid();
        break;
      }
      case 'scheduledDays': {
        isValid = Array.isArray(val) && val?.length > 0;
        break;
      }
      case 'flightNumber': {
        isValid = val !== '' && Number(val) >= 0 && Number(val) <= 9999;
        break;
      }
      case 'type': {
        isValid = Object.values(TripTypeEnum).includes(val);
        break;
      }
      case 'kind': {
        isValid = Object.values(TripKindEnum).includes(val);
        break;
      }
      case 'status': {
        isValid = Object.values(TripStatusEnum).includes(val);
        break;
      }
      case 'crewId': {
        isValid = trip?.payerProviderId !== upsProviderId ? undefined : Validation.isNumber(parseInt(val));
        break;
      }
      case 'firstName': {
        isValid = trip?.payerProviderId !== upsProviderId ? undefined : !!val && Validation.isAlphanumeric(val);
        break;
      }
      case 'lastName': {
        isValid = trip?.payerProviderId !== upsProviderId ? undefined : !!val && Validation.isAlphanumeric(val);
        break;
      }
      case 'stops': {
        isValid =
          !val?.length || !val.map((stop: any): boolean => !!Validation.isValidUUID(stop?.locationId) && !!stop?.scheduled).includes(false);
        break;
      }
    }
    return { valid: getValidityType(isValid, isDiff), message };
  };
  const result = {};
  keys.forEach((key: string): any => (result[key] = validateKey(key, trip?.[key])));
  return result;
};

type TripViewProps = { value: Trip | string | {}; mode?: 'create' | 'edit'; title?: string; preview?: any };
type TripViewState = {
  data: Trip;
};
const initTripViewState: TripViewState = {
  data: null,
};

const TripView = ({ value = {}, mode = 'edit', title, preview }: TripViewProps) => {
  const [state, setState] = useState(initTripViewState);
  const { data } = state;
  const [loading, setLoading] = useState(false);
  const [{ config: { upsProviderId = '' } = {} } = {}] = useAppState();
  const [, { handleCreateTripBulk, handleUpdateTripBulk }] = useTrips();

  //const [{ data: trip, loading: loadingTrip }, { refetch: getTripForModal }] = useGetTripForModal();

  const getTripData = async (value: string): Promise<void> => {
    setLoading(true);
    try {
      const trip = await getTripForModal(value);
      setState((current: any): any => ({ ...current, data: trip || {} }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect((): void => {
    //if (stringify.compare(previousValue.current, value)) return;
    //previousValue.current = value;
    if (typeof value === 'string') getTripData(value);
    else setState((current: TripViewState): TripViewState => ({ ...current, data: value as Trip }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      {!data && <LoadingBlur loading={true} />}
      {!!data && (
        <FormContainer
          //ref={formContainerRef}
          name="EditRatesForm"
          initialValues={data}
          options={{
            //loadingSpinner: { loading: loading || !data },
            validator: updateTripValidator,
            //bulk: selected,
          }}
          onSubmit={async () => {}}
          //onSubmit={handleSubmit}
          formAs={({
            values,
            validation: [isValid, validity],
            setValues,
            onChange,
            onSubmit,
            disabled,
          }: {
            values: any;
            validation: [boolean, Record<string, Validation.Validity>, boolean];
            setValues: Dispatch<SetStateAction<Record<string, unknown>>>;
            onChange: OnChange;
            onSubmit: () => Promise<void>;
            disabled: Record<string, boolean>;
          }): ReactNode => {
            console.log(values);
            // const handleSubmit = async (): Promise<void> => {
            //   const { selected = [], persist = false } = state;
            //   const { airportFilter: _airportFilter, ...values } = state?.values || {};
            //   values.pilots = values?.pilots || 0;
            //   values.attendants = values?.attendants || 0;
            //   if (values?.id) {
            //     await handleUpdateTripBulk(values, selected || []);
            //   } else {
            //     await handleCreateTripBulk(values);
            //   }
            //   onSubmit(state);
            //   if (!persist) onHide();
            // };
            // const onSaveAndCopy = async (): Promise<void> => {
            //   await handleCreateTripBulk(values, selected || []);
            //   onSubmit(state);
            // };

            // const mode = !values?.id ? 'create' : 'edit';
            // const selectedCount = Object.values(selected).length || 0;
            // const uuid = useUuid();

            // const onAddStop = (): any => {
            //   const currentDatetime = new Datetime();
            //   setState((current: any): any => ({
            //     ...current,
            //     values: {
            //       ...(current?.values || {}),
            //       stops: [
            //         ...(current?.values?.stops || []),
            //         {
            //           id: new Date().getTime(),
            //           airportFilter: current?.values?.airportCode,
            //           scheduled: current?.values?.scheduled
            //             ? new Datetime(current?.values?.scheduled).setTime(currentDatetime.fullTime).toString()
            //             : currentDatetime.toString(),
            //         },
            //       ],
            //     },
            //   }));
            // };
            // const onBlurFlightNumber = (): void => {
            //   setState((current: any): any => ({
            //     ...current,
            //     values: { ...(current?.values || {}), flightNumber: `${current?.values?.flightNumber || ''}`.replace(/^[0]+/, '').padStart(4, '0') },
            //   }));
            // };

            // const validity = useMemo(
            //   (): any => {
            //     if (mode === 'create' && !keys.includes('scheduledDays')) keys.push('scheduledDays');
            //     if (mode === 'edit' && keys.includes('scheduledDays')) keys.splice(keys.indexOf('scheduledDays'), 1);
            //     const result = (validator || ((): any => ({})))(values, upsProviderId, selected);
            //     return result;
            //   },
            //   // eslint-disable-next-line react-hooks/exhaustive-deps
            //   [validator, values, upsProviderId]
            // );
            // const isValid = Validation.isValid(validity);

            // const TripStopComponent = (props: any): JSX.Element => (
            //   <TripStop
            //     onDelete={(): any =>
            //       setState((current: any): any => ({
            //         ...current,
            //         values: {
            //           ...(current?.values || {}),
            //           stops: (current?.values?.stops || []).filter((_stop: any, i: number): boolean => i !== props?.index),
            //         },
            //       }))
            //     }
            //     onChange={(event: any): void => {
            //       const { name, value } = event?.target || {};
            //       switch (name.split('.').pop()) {
            //         case 'locationId':
            //           return value !== '' ? onChange(event) : undefined;
            //         case 'airportFilter':
            //           onChange({ target: { name: `values.stops.${props?.index}.locationId`, value: '' } });
            //         default:
            //           return onChange(event);
            //       }
            //     }}
            //     valid={{
            //       locationId: Validation.isValidUUID(props?.data?.locationId) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
            //     }}
            //     options={{
            //       filterLocationByAirport: true,
            //     }}
            //     {...props}
            //   />
            // );
            // // eslint-disable-next-line react-hooks/exhaustive-deps
            // const MemoizedTripStop = useMemo((): ((props: any) => JSX.Element) => TripStopComponent, [values?.airportCode, values?.stops]);
            // const gotoManifestLink = useMemo((): ReactNode => {
            //   if (!values?.manifestImportId) return undefined;
            //   return (
            //     <Button
            //       variant="icon"
            //       onClick={(): void => {
            //         onHide();
            //         navigate(`/manifests/${values?.manifestImportId}`);
            //       }}
            //     >
            //       Goto Manifest
            //     </Button>
            //   );
            // }, [onHide, values?.manifestImportId]);

            return (
              <Container fluid>
                <DynamicContent.Title>{title}</DynamicContent.Title>
                <Row>
                  <Col md={3}>
                    <FormField
                      label="Type:"
                      placeholder="Select Type"
                      name="type"
                      value={values?.type || ''}
                      onChange={onChange}
                      valid={validity?.type?.valid}
                      searchable
                      options={{
                        input: {
                          as: SelectType,
                        },
                      }}
                    />
                    <FormField
                      label="Client:"
                      placeholder="Select Client"
                      name="payerProviderId"
                      value={values?.payerProviderId || ''}
                      onChange={(event) => {
                        const value = event?.target?.value;
                        onChange.bulk([{ target: { name: 'providerId', value } }, { target: { name: 'payerProviderId', value } }]);
                      }}
                      valid={validity?.payerProviderId?.valid}
                      searchable
                      options={{
                        input: {
                          as: SelectClient,
                        },
                      }}
                    />
                    <FormField
                      label="Airline:"
                      placeholder="Select Airline"
                      name="servicerIataAirlineCode"
                      value={values?.servicerIataAirlineCode || ''}
                      onChange={onChange}
                      valid={validity?.servicerIataAirlineCode?.valid}
                      searchable
                      options={{
                        input: {
                          as: SelectAirlineIata,
                        },
                      }}
                    />
                    <FormField
                      label="Airport:"
                      placeholder="Select Airport"
                      name="airportCode"
                      value={values?.airportCode || ''}
                      onChange={(event: any): void => {
                        const { value } = event?.target || {};
                        if (!value) return;
                        onChange.bulk([
                          { target: { name: 'airportCode', value } },
                          { target: { name: 'doLocationId', value } },
                          { target: { name: 'doAirportFilter', value } },
                          {
                            target: {
                              name: 'stops',
                              value: (values?.stops || []).map((stop: any): any => ({
                                ...stop,
                                airportFilter: value,
                                locationId: '',
                              })),
                            },
                          },
                        ]);
                      }}
                      valid={validity?.airportCode?.valid}
                      searchable
                      options={{
                        input: {
                          as: SelectAirport,
                        },
                      }}
                    />
                    <FormField
                      label={'Arrival/\nDeparture:'}
                      placeholder="Select Kind"
                      name="kind"
                      value={values?.kind || ''}
                      onChange={(event): void => {
                        const { value } = event?.target || {};
                        const { puLocationId = '', doLocationId = '' } = values || {};
                        onChange.bulk([
                          { target: { name: 'kind', value } },
                          { target: { name: 'puLocationId', value: doLocationId } },
                          { target: { name: 'doLocationId', value: puLocationId } },
                        ]);
                      }}
                      valid={validity?.kind?.valid}
                      searchable
                      options={{
                        input: {
                          as: SelectKind,
                        },
                      }}
                    />
                    <FormField label={'Loop Name:'} name="loopName" value={values?.loopName || ''} onChange={onChange} />
                  </Col>
                  <Col xs={12} md={4}>
                    <FormField
                      label="Flight Number:"
                      name="flightNumber"
                      value={!Validation.isNil(values?.flightNumber) ? `${values?.flightNumber}`.padStart(4, '0') : ''}
                      onChange={(event: any): void => {
                        event.target.value = event.target.value.replace(/^[0]+/, '').padStart(4, '0').substring(0, 4) || '';
                        onChange(event);
                      }}
                      //onBlur={onBlurFlightNumber}
                      valid={validity?.flightNumber?.valid}
                    />
                    <div className="d-flex gap-3 {margin:-1.2rem|0;}">
                      <FormField
                        label="PLT:"
                        type="number"
                        name="pilots"
                        value={values?.pilots || 0}
                        onChange={onChange}
                        valid={values?.pilots > 0 || values?.attendants > 0}
                        min={0}
                        max={255}
                      />
                      <FormField
                        label="F/A:"
                        type="number"
                        name="attendants"
                        value={values?.attendants || 0}
                        onChange={onChange}
                        valid={values?.attendants > 0 || values?.pilots > 0}
                        min={0}
                        max={255}
                      />
                    </div>
                    <FormField
                      type="number"
                      label="Crew ID:"
                      name="crewId"
                      value={values?.crewId || ''}
                      onChange={onChange.int}
                      valid={validity?.crewId?.valid}
                      options={{
                        input: {
                          className: 'CrewId',
                        },
                      }}
                    />
                    <FormField
                      label="First Name:"
                      name="firstName"
                      value={values?.firstName || ''}
                      onChange={onChange}
                      valid={validity?.firstName?.valid}
                    />

                    <FormField
                      label="Last Name:"
                      name="lastName"
                      value={values?.lastName || ''}
                      onChange={onChange}
                      valid={validity?.lastName?.valid}
                    />
                    <div className="d-flex gap-3 {margin:-1.2rem|0;}">
                      <FormField
                        label="Include in\nFuture Manifests?"
                        //id={`future_${uuid}`}
                        type="switch"
                        name="fromManifest"
                        checked={values?.fromManifest !== undefined ? !!values?.fromManifest : true}
                        onChange={onChange.toggleInt}
                      />
                      <FormField label="Split:" type="number" name="split" value={values?.split || ''} onChange={onChange} />
                    </div>
                  </Col>
                  {!preview && (
                    <Col xs={12} md={5}>
                      <div className="d-flex gap-3 {margin:0|0|-1.2rem|0;}">
                        <FormField
                          label={'Flags:'}
                          placeholder="No Flags"
                          name="flags"
                          value={(values?.flags || []).map((node) => node.id || node)}
                          onChange={onChange}
                          //disabled={selectedCount > 1}
                          searchable
                          options={{
                            input: { as: SelectFlag, className: 'Flags' },
                          }}
                        />
                        <FormField
                          label={'Status:'}
                          placeholder="Select Status"
                          name="status"
                          value={values?.status || ''}
                          onChange={(event: any): void => (event?.target?.value ? onChange(event) : undefined)}
                          valid={validity?.status?.valid}
                          searchable
                          options={{
                            input: { as: SelectStatus, className: 'Status' },
                          }}
                        />
                      </div>
                      <FormField
                        type="textarea"
                        label="Comments:"
                        name="comments"
                        value={values?.comments || ''}
                        onChange={onChange}
                        options={{ input: { className: 'Comments {h:11.6rem;resize:none;}' } }}
                      />
                      {mode === 'edit' && (
                        <div className="d-flex gap-3 {margin:-1.2rem|0;}">
                          <FormField
                            label="Increment?"
                            //id={`increment_${uuid}`}
                            type="switch"
                            name="increment"
                            checked={!!values?.increment}
                            onChange={onChange.toggle}
                          />
                          <FormField
                            label="Days:"
                            type="number"
                            name="days"
                            value={values?.days || 0}
                            //disabled={!values?.increment}
                            onChange={onChange.int}
                          />
                        </div>
                      )}
                      {mode === 'edit' && (
                        <FormField
                          name="scheduled"
                          label="P/U Date:"
                          type="date"
                          value={values?.scheduled || ''}
                          onChange={(event: any): void => {
                            const { value } = event?.target || {};
                            const currentScheduledTime = values?.scheduled?.split('T')?.[1];
                            const newScheduledTime = currentScheduledTime ? value.concat(' ', currentScheduledTime) : value;
                            const result: EventObject[] = [
                              { target: { name: 'scheduled', value: new Datetime(newScheduledTime).toString() } },
                            ];
                            if (values?.stops?.length) {
                              const originalStops = [...(values?.stops || [])];
                              const stops = originalStops.map(
                                (stop: Stop): Stop => ({
                                  ...stop,
                                  scheduled: value ? new Datetime(stop?.scheduled || undefined).setDate(value).toString() : null,
                                })
                              );
                              result.push({ target: { name: 'stops', value: stops } });
                            }
                            onChange.bulk(result);
                          }}
                          valid={validity?.scheduled?.valid}
                          disabled={!!values?.increment}
                          feedback={values?.increment ? 'Disable increment to edit scheduled date.' : ''}
                        />
                      )}
                      {mode === 'create' && (
                        <DateSelector
                          name="scheduledDays"
                          label="P/U Date:"
                          value={values?.scheduledDays || []}
                          onChange={onChange}
                          valid={!!validity?.scheduledDays?.valid}
                          selector="day"
                          range={false}
                          multiple
                        />
                      )}
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col>
                    <Modal.Title className="border-0 d-flex mt-4">
                      <Badge className="rounded bg-black bg-opacity-75">
                        <i className={`fa fa-2x fa-location-dot {scale(0.75)}`} />
                      </Badge>
                      <span className="flex-grow-1 h-100 ms-3 mt-2 border-bottom border-gray">Trip Stops</span>
                    </Modal.Title>
                    <Row className="mt-3">
                      <Col xs={12} sm={8}>
                        <TripStop
                          data={{
                            ...values,
                            type: 'PU',
                            airportFilter: values?.airportCode || '',
                            locationId: values?.puLocationId,
                            scheduled: values?.scheduled,
                            actual: values?.puActualDatetime,
                            completed: values?.puCompletedDatetime,
                            kind: values?.kind,
                            doLocationId: values?.doLocationId || '',
                            stops: values?.stops || [],
                          }}
                          valid={{ locationId: validity?.puLocationId?.valid, scheduled: validity?.scheduled?.valid }}
                          disabled
                          index={-1}
                          onChange={(event: any): any => {
                            const { name, value } = event?.target || {};
                            switch (name.split('.').pop()) {
                              case 'locationId':
                                return value !== '' ? onChange({ target: { name: 'puLocationId', value } }) : undefined;
                              default:
                                return onChange({ target: { name: name.split('.').pop(), value } });
                            }
                          }}
                          options={{
                            autoSelectLocation: values?.kind === TripKindEnum.Arr,
                            location: {
                              props: {
                                query: {
                                  airportCode: values?.airportCode || null,
                                },
                              },
                            },
                          }}
                        />
                        <DragAndDrop
                          list={values?.stops || []}
                          id="id"
                          name="stops"
                          replaceProperties={['id', 'type', 'locationId', 'airportFilter']}
                          onChange={onChange}
                          //as={MemoizedTripStop}
                        />
                        <TripStop
                          data={{
                            ...values,
                            type: 'DO',
                            locationId: values?.doLocationId,
                            scheduled: values?.doScheduledDatetime,
                            actual: values?.doActualDatetime,
                            completed: values?.completion?.datetime,
                            airportFilter:
                              values?.doAirportFilter || values?.doLocation?.airports?.[0]?.airportCode || values?.airportCode || '',
                            kind: values?.kind,
                            puLocationId: values?.puLocationId || '',
                            stops: values?.stops || [],
                          }}
                          valid={{ locationId: validity?.doLocationId?.valid }}
                          disabled
                          index={values?.stops?.length || 0}
                          onChange={(event: any): any => {
                            const { name, value } = event?.target || {};
                            switch (name.split('.').pop()) {
                              case 'locationId':
                                return value !== '' ? onChange({ target: { name: 'doLocationId', value } }) : undefined;
                              case 'airportFilter':
                                onChange.bulk([
                                  { target: { name: 'doLocationId', value: '' } },
                                  { target: { name: 'doAirportFilter', value } },
                                ]);
                              default:
                                return onChange({ target: { name: name.split('.').pop(), value } });
                            }
                          }}
                          options={{
                            autoSelectLocation: values?.kind === TripKindEnum.Dep,
                            filterLocationByAirport: true,
                          }}
                        />
                      </Col>
                      <Col>
                        <TripStopTimeSchedule
                          stop={{
                            type: 'PU',
                            locationId: values?.puLocationId,
                            scheduled: values?.scheduled,
                            actual: values?.puActualDatetime,
                            completed: values?.puCompletedDatetime,
                          }}
                          onChange={(event: any): any => {
                            const { name, value } = event?.target || {};
                            switch (name.split('.').pop()) {
                              case 'scheduled':
                                return onChange.time({ target: { name: 'scheduled', value } });
                              case 'actual':
                                return onChange.time({ target: { name: 'puActualDatetime', value } });
                              case 'completed':
                                return onChange.time({ target: { name: 'puCompletedDatetime', value } });
                              default:
                                return onChange({ target: { name: name.split('.').pop(), value } });
                            }
                          }}
                          index={-1}
                          valid={{ scheduled: validity?.scheduled?.valid }}
                        />
                        {values?.stops?.map((stop: any, s: number): JSX.Element => {
                          return (
                            <TripStopTimeSchedule
                              //disabled={selectedCount > 1}
                              stop={stop}
                              onChange={onChange}
                              index={s}
                              key={`stop.${s}.time`}
                              valid={{ scheduled: stop?.scheduled ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID }}
                            />
                          );
                        })}
                        <TripStopTimeSchedule
                          stop={{
                            type: 'DO',
                            locationId: values?.doLocationId,
                            scheduled: values?.doScheduledDatetime,
                            actual: values?.doActualDatetime,
                            completed: values?.completion?.datetime,
                          }}
                          onChange={(event: any): any => {
                            const { name, value } = event?.target || {};
                            switch (name.split('.').pop()) {
                              case 'scheduled':
                                return onChange.time({ target: { name: 'doScheduledDatetime', value } });
                              case 'actual':
                                return onChange.time({ target: { name: 'doActualDatetime', value } });
                              case 'completed':
                                return onChange.time({ target: { name: 'completion.datetime', value } });
                              default:
                                return onChange({ target: { name: name.split('.').pop(), value } });
                            }
                          }}
                          index={values?.stops?.length || 0}
                          disabled
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <FormContainer.Footer>
                  <Row>
                    <Col>
                      <Button
                        className="w-100"
                        variant="primary"
                        onClick={onSubmit}
                        //disabled={!valid || !formContainerRef?.current?.isDirty?.()}
                        name="RESOLVE"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </FormContainer.Footer>
              </Container>
            );
          }}
        />
      )}
    </>
  );
};

export default TripView;
