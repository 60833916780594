import './styles.scss';

import { Alias, SortDirectionEnum, Trip } from '@/models/gen/graphql';
import React, { useLayoutEffect, useRef, useState } from 'react';
import useTripTableState, { TripSortColumnEnum } from '@/features/Trips/components/TripsTable/hook';

import ActualCell from '@/features/Trips/components/HtmlTripsTable/Cells/ActualTimeCell';
import AssignDriverCell from '@/features/Trips/components/HtmlTripsTable/Cells/AssignDriverCell';
import AssignVehicleCell from '@/features/Trips/components/HtmlTripsTable/Cells/AssignVehicleCell';
import CombineCell from '@/features/Trips/components/HtmlTripsTable/Cells/CombineCell';
import CompletionCell from '@/features/Trips/components/HtmlTripsTable/Cells/CompletionCell';
import { DATE_FE_FORMAT_SHORT } from '@/constants';
import { Datetime } from '@/utils/dates';
import FcrCell from '@/features/Trips/components/HtmlTripsTable/Cells/FcrCell';
import FlagAndCommunicationCell from '@/features/Trips/components/HtmlTripsTable/Cells/FlagAndCommunicationCell';
import { LoadingBlur } from '@/components/LoadingSpinner';
import LocationCell from '@/features/Trips/components/HtmlTripsTable/Cells/LocationCell';
import RateCell from '@/features/Trips/components/HtmlTripsTable/Cells/RateCell';
import Tooltip from '@/features/Trips/components/Tooltip';
import { TripsTableRowEventHandlers } from '@/features/Trips/components/TripsTable/TripTableRow';
import { formatTripTitle } from '@/features/Trips/components/TripsTable/utils';
import { getClasses } from '@/utils/strings';
import { useVirtualizer } from '@tanstack/react-virtual';
import { zeroPadFlightNumber } from '@/utils/numbers';

const DEFAULT_MAX_HEIGHT = 300;
const ROW_HEIGHT = 45;
const FULLSCREEN_PADDING = 90;
const OVER_SCAN = 20;
type HtmlTripsTableProps = {
  rows: string[];
  loading: boolean;
  title: string;
  shortcuts?: (() => React.ReactNode) | React.ReactNode;
} & TripsTableRowEventHandlers;
const HtmlTripsTable = ({ rows, loading, title, shortcuts: Shortcuts, ...props }: HtmlTripsTableProps) => {
  // state
  const [maxHeight, setMaxHeight] = useState(DEFAULT_MAX_HEIGHT);
  // ref
  const tableRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: (): HTMLElement => tableRef.current,
    estimateSize: (): number => ROW_HEIGHT,
    overscan: OVER_SCAN,
  });

  // calculate max height on mount
  useLayoutEffect((): void => {
    const node = document.querySelector('.RouteContent');
    const { y } = node.getBoundingClientRect();
    const result = window.innerHeight - y - FULLSCREEN_PADDING - headerRef.current.offsetHeight || 0;
    setMaxHeight(result);
  }, []);

  return (
    <div className="HtmlTripsTable">
      <div className="HtmlTripsTable-Header" ref={headerRef}>
        {title && <div className="HtmlTripsTable-Title">{title}</div>}
        {Shortcuts && <div className="HtmlTripsTable-Shortcuts">{Shortcuts instanceof Function ? <Shortcuts /> : Shortcuts}</div>}
      </div>
      <section className="table-container" ref={tableRef} style={{ height: maxHeight }}>
        <LoadingBlur loading={loading} />
        <div className="table-header">
          <TripTableHeader />
        </div>
        <div className="table-body" style={{ position: 'relative', height: `${rowVirtualizer.getTotalSize() || ROW_HEIGHT}px` }}>
          {!rows.length && (
            <div className="table-row empty">
              <div className="table-cell text-center">
                <i className="fa fa-inbox" />
                <span>No Records</span>
              </div>
            </div>
          )}
          {/* Render only visible rows */}
          {!!rows.length &&
            rowVirtualizer.getVirtualItems().map((virtualRow) => (
              <TripsTableRow
                {...props}
                key={virtualRow.index}
                ref={rowVirtualizer.measureElement}
                data-index={virtualRow.index}
                style={{
                  position: 'absolute',
                  top: `${virtualRow.start}px`,
                  left: 0,
                  height: `${virtualRow.size}px`,
                  transition: 'top 0.2s ease-in-out', // Smooth transition
                }}
                index={virtualRow.index}
                rowId={rows[virtualRow.index]}
              />
            ))}
        </div>
      </section>
    </div>
  );
};

const SortCell = ({ children, onSort, direction }) => (
  <button className="sort" onClick={onSort}>
    <span>{children}</span>
    <Tooltip content={direction ?? (direction === SortDirectionEnum.Asc ? 'Ascending' : 'Descending')}>
      <span>
        <i className={`fa ${!!direction ? `fa-sort-${direction.toLowerCase()}` : 'fa-sort opacity-25'} `} />
      </span>
    </Tooltip>
  </button>
);

const TripTableSelectAll = (): React.JSX.Element => {
  const trips = useTripTableState(({ state }) => state.trips);
  const selected = useTripTableState(({ state }) => state.selected);
  const onSelectAll = useTripTableState(({ state }) => state.onSelectAll);

  return <input type="checkbox" checked={trips.size === selected.size} onChange={onSelectAll} />;
};

const TripTableHeader = (): React.JSX.Element => {
  const sorting = useTripTableState(({ state }) => state.sorting);
  const onSort = useTripTableState(({ state }) => state.onSort);

  const columnSortMap = sorting.reduce((acc, curr) => {
    acc[curr.column] = curr.direction;
    return acc;
  }, {}) as Record<TripSortColumnEnum, SortDirectionEnum>;
  return (
    <>
      <div className="table-header-cell">
        <TripTableSelectAll />
      </div>
      <div className="table-header-cell">
        <div className="w-100 d-flex justify-content-around">
          <i className="sv sv-flag-empty fs-5 {padding-top:0.05rem;}" />
          <i className="sv sv-message2 fs-5" />
        </div>
      </div>
      <div className="table-header-cell">
        <SortCell onSort={(): void => onSort(TripSortColumnEnum.type)} direction={columnSortMap[TripSortColumnEnum.type]}>
          Type
        </SortCell>
      </div>
      <div className="table-header-cell">
        <SortCell
          onSort={(): void => onSort(TripSortColumnEnum.latestScheduled)}
          direction={columnSortMap[TripSortColumnEnum.latestScheduled]}
        >
          Date
        </SortCell>
      </div>
      <div className="table-header-cell">
        <SortCell onSort={(): void => onSort(TripSortColumnEnum.latestScheduledUtc)} direction={columnSortMap.latestScheduledUtc}>
          Sch
        </SortCell>
      </div>
      <div className="table-header-cell">
        <SortCell onSort={(): void => onSort(TripSortColumnEnum.actual)} direction={columnSortMap[TripSortColumnEnum.actual]}>
          Act
        </SortCell>
      </div>
      <div className="table-header-cell">
        <SortCell onSort={(): void => onSort(TripSortColumnEnum.airportCode)} direction={columnSortMap[TripSortColumnEnum.airportCode]}>
          City
        </SortCell>
      </div>
      <div className="table-header-cell">
        <SortCell onSort={(): void => onSort(TripSortColumnEnum.servicerIataAirlineCode)} direction={columnSortMap.servicerIataAirlineCode}>
          Al
        </SortCell>
      </div>
      <div className="table-header-cell">
        <SortCell onSort={(): void => onSort(TripSortColumnEnum.flightNumber)} direction={columnSortMap[TripSortColumnEnum.flightNumber]}>
          Flt
        </SortCell>
      </div>
      <div className="table-header-cell">
        <SortCell onSort={(): void => onSort(TripSortColumnEnum.pilots)} direction={columnSortMap[TripSortColumnEnum.pilots]}>
          Plt
        </SortCell>
      </div>
      <div className="table-header-cell">
        <SortCell onSort={(): void => onSort(TripSortColumnEnum.attendants)} direction={columnSortMap[TripSortColumnEnum.attendants]}>
          FA
        </SortCell>
      </div>
      <div className="table-header-cell">
        <SortCell onSort={(): void => onSort(TripSortColumnEnum.driverId)} direction={columnSortMap[TripSortColumnEnum.driverId]}>
          Drv
        </SortCell>
      </div>
      <div className="table-header-cell">
        <SortCell onSort={(): void => onSort(TripSortColumnEnum.vehicleId)} direction={columnSortMap[TripSortColumnEnum.vehicleId]}>
          Van
        </SortCell>
      </div>
      <div className="table-header-cell">
        <SortCell onSort={(): void => onSort(TripSortColumnEnum.puLocationId)} direction={columnSortMap[TripSortColumnEnum.puLocationId]}>
          P/U
        </SortCell>
      </div>
      <div className="table-header-cell">
        <SortCell onSort={(): void => onSort(TripSortColumnEnum.doLocationId)} direction={columnSortMap[TripSortColumnEnum.doLocationId]}>
          D/O
        </SortCell>
      </div>
      <div className="table-header-cell">
        <SortCell onSort={(): void => onSort(TripSortColumnEnum.payerProvider)} direction={columnSortMap[TripSortColumnEnum.payerProvider]}>
          Clt
        </SortCell>
      </div>
      <div className="table-header-cell text-center">
        <i className="sv sv-completion fs-5" />
      </div>
      <div className="table-header-cell text-center">
        <i className="sv sv-fcr fs-5" />
      </div>
      <div className="table-header-cell text-center">
        <i className="sv sv-combine-icon fs-5 text-secondary" />
      </div>
      <div className="table-header-cell">
        <SortCell onSort={(): void => onSort(TripSortColumnEnum.rateAmount)} direction={columnSortMap[TripSortColumnEnum.rateAmount]}>
          Rate
        </SortCell>
      </div>
    </>
  );
};

type TripTableRowProps = TripsTableRowEventHandlers & {
  rowId: string;
  index: number;
  style: React.CSSProperties;
};
const TripsTableRow = React.memo(
  React.forwardRef(
    (
      {
        rowId,
        index,
        onEditTrip,
        onEditFlag,
        onEditCommunication,
        onEditCompletion,
        onEditFcr,
        onEditCombine,
        onEditRateReport,
        confirmIllegalCombines,
        style,
        ...props
      }: TripTableRowProps,
      ref: React.ForwardedRef<HTMLTableRowElement>
    ): React.JSX.Element => {
      const data = useTripTableState(({ state }) => state.trips.get(rowId) || ({} as Trip));
      const isSelected = useTripTableState(({ state }) => state.selected.get(rowId));
      const onSelect = useTripTableState(({ state }) => state.onSelect);
      const onSetRow = useTripTableState(({ state }) => state.onSetRow);

      const scheduledDatetime = new Datetime(data.scheduled);
      const scheduledUtcDatetime = new Datetime(data.scheduledUtc);
      const flightNumber = zeroPadFlightNumber(data.flightNumber);
      const puLocationAliases = (data.puLocation?.aliases || []).map(({ name }: Alias): string => name);

      const doLocationAliases = (data.doLocation?.aliases || []).map(({ name }: Alias): string => name);

      const title = formatTripTitle(data.servicerIataAirlineCode, data.flightNumber, data.scheduled);

      return (
        <div
          {...props}
          ref={ref}
          style={style}
          onDoubleClick={(): void => onEditTrip(data)}
          className={getClasses(
            'table-row',
            isSelected ? 'selected' : '',
            data.kind,
            data.type,
            data.status,
            data.isLate ? 'LATE' : '',
            isUpcomingTrip(scheduledUtcDatetime) ? 'UPCOMING' : '',
            data.state?.completion ? 'COMPLETED' : '',
            data.deletedAt ? 'DELETED' : '',
            data.curbsideAt ? 'CURBSIDE' : ''
          )}
        >
          <div className="table-cell">
            <input type="checkbox" name={`row-select_${index}`} checked={!!isSelected} onChange={(): void => onSelect(rowId)} />
          </div>
          <div className="table-cell">
            <FlagAndCommunicationCell
              flags={data.flags}
              hasCommunication={!!data.communications?.length}
              onFlagClick={(): void => onEditFlag(data.id, data.servicerIataAirlineCode, flightNumber, data.scheduled)}
              onCommunicationClick={(): void =>
                onEditCommunication(data.id, data.servicerIataAirlineCode, flightNumber, data.scheduled, data.offset)
              }
            />
          </div>
          <div className="table-cell">{data.type || '--'}</div>
          <div className="table-cell">{scheduledDatetime.format(DATE_FE_FORMAT_SHORT)}</div>
          <div className="table-cell">{scheduledDatetime.time || '--'}</div>
          <div className="table-cell">
            <ActualCell
              actual={data.trackFlight?.actual}
              arrivalGate={data.trackFlight?.arrivalGate}
              arrivalTerminal={data.trackFlight?.arrivalTerminal}
              label={data.trackFlight?.label}
              kind={data.kind}
              scheduled={data.scheduled}
            />
          </div>
          <div className="table-cell">{data.airportCode || '--'}</div>
          <div className="table-cell">{data.servicerIataAirlineCode || '--'}</div>
          <div className="table-cell">{data.flightNumber > 0 ? flightNumber : '--'}</div>
          <div className="table-cell">{data.pilots ?? '--'}</div>
          <div className="table-cell">{data.attendants ?? '--'}</div>
          <div className="table-cell">
            <AssignDriverCell
              rowId={data.id}
              title={title}
              airportCode={data.airportCode}
              actual={data.trackFlight?.actual}
              driver={data.driver}
              providerId={data.providerId}
              puLocationId={data.puLocationId}
              doLocationId={data.doLocationId}
              combineId={data.combineId}
              combineType={data.combineType}
              scheduled={data.scheduled}
              pilots={data.pilots}
              attendants={data.attendants}
              onSetRow={onSetRow}
              confirmIllegalCombines={confirmIllegalCombines}
            />
          </div>
          <div className="table-cell">
            <AssignVehicleCell
              actual={data.trackFlight?.actual}
              scheduled={data.scheduled}
              airportCode={data.airportCode}
              driverId={data.driverId}
              rowId={data.id}
            />
          </div>
          <div className="table-cell">
            <LocationCell
              name={data.puLocation?.name}
              address={data.puLocation?.address}
              stateCode={data.puLocation?.stateCode}
              zipCode={data.puLocation?.zipCode}
              cityName={data.puLocation?.cityName}
              aliases={puLocationAliases}
            />
          </div>
          <div className="table-cell">
            <LocationCell
              name={data.doLocation?.name}
              address={data.doLocation?.address}
              stateCode={data.doLocation?.stateCode}
              zipCode={data.doLocation?.zipCode}
              cityName={data.doLocation?.cityName}
              aliases={doLocationAliases}
            />
          </div>
          <div className="table-cell">{data.payerProvider?.displayName || '--'}</div>
          <div className="table-cell">
            <CompletionCell
              datetimeOfChange={data.state?.datetimeOfChange}
              displayName={data.state?.displayName}
              displayNameShort={data.state?.displayNameShort}
              onClick={(): void =>
                onEditCompletion(
                  data.id,
                  data.servicerIataAirlineCode,
                  flightNumber,
                  data.scheduled,
                  data.state?.completion,
                  data.completionId
                )
              }
            />
          </div>
          <div className="table-cell">
            <FcrCell
              count={data.fcrs?.length}
              onClick={(): void => onEditFcr(data.id, data.servicerIataAirlineCode, flightNumber, data.scheduled)}
            />
          </div>
          <div className="table-cell">
            <CombineCell
              combineId={data.combineId}
              combineType={data.combineType}
              onClick={(): void => onEditCombine(data.id, data.servicerIataAirlineCode, flightNumber, data.scheduled, data.combineId)}
            />
          </div>
          <div className="table-cell">
            <RateCell rate={data.rate?.rate} rowId={data.id} onSetRow={onSetRow} onEditRateReport={onEditRateReport} />
          </div>
        </div>
      );
    }
  )
);

const isUpcomingTrip = (utcDatetime: Datetime): boolean =>
  utcDatetime.isSameUtc(undefined, 'day') && utcDatetime.diffUtc(undefined, 'hour') < 1;

export default React.memo(HtmlTripsTable);
