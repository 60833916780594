import React, { Ref, forwardRef, useImperativeHandle, useMemo } from 'react';

import DynamicContent from '@/components/DynamicContent';
import TripView from '@/features/TripsConcept/views/trip';

export type TripsModalRef = {
  setState: (mode: TripsModalState) => void;
};
type TripsModalState = {
  mode: 'editing' | 'creating' | null;
  tripId: string | null;
  tab?: TripsModalTab;
};
export type TripsModalTab = 'trip' | 'flag' | 'communication' | 'completion' | 'fcr' | 'combine' | 'rate';
const initState: TripsModalState = { mode: null, tripId: null, tab: 'trip' };

const TripsModal = forwardRef(({ ...props }: any, ref: Ref<TripsModalRef>): JSX.Element => {
  const [state, setState] = React.useState<TripsModalState>(initState);
  const { mode, tripId, tab } = state;

  const onClickModalTab = (tabName: TripsModalTab) => {
    setState((current) => ({ ...current, tab: tabName }));
  };

  const [View, title] = useMemo(() => {
    switch (tab) {
      case 'trip':
        return [TripView, `${mode === 'editing' ? 'Edit' : 'Create'} Trip`];
      default:
        return [() => <></>];
    }
  }, [tab, mode]);

  useImperativeHandle(ref, () => ({ setState: (mode: TripsModalState) => setState(mode) }));

  return (
    <DynamicContent
      className="p-3"
      show={mode !== null}
      as="drawer"
      onHide={() => setState({ mode: null, tripId: null })}
      options={{
        drawer: {
          props: {
            title: mode === 'creating' ? 'Create Trip' : 'Edit Trip',
            width: 720,
            tabs: [
              { icon: <i className="sv sv-plane" />, tooltip: 'Trip', onClick: () => onClickModalTab('trip'), active: tab === 'trip' },
              {
                icon: <i className="sv sv-flag-empty" />,
                tooltip: 'Flags',
                onClick: () => onClickModalTab('flag'),
                active: tab === 'flag',
              },
              {
                icon: <i className="sv sv-message2" />,
                tooltip: 'Communications',
                onClick: () => onClickModalTab('communication'),
                active: tab === 'communication',
              },
              {
                icon: <i className="sv sv-completion" />,
                tooltip: 'Completion',
                onClick: () => onClickModalTab('completion'),
                active: tab === 'completion',
              },
              { icon: <i className="sv sv-fcr" />, tooltip: 'Complaints', onClick: () => onClickModalTab('fcr'), active: tab === 'fcr' },
              {
                icon: <i className="sv sv-combine-icon" />,
                tooltip: 'Combine',
                onClick: () => onClickModalTab('combine'),
                active: tab === 'combine',
              },
              {
                icon: <i className="sv sv-coin-dollar" />,
                tooltip: 'Rate',
                onClick: () => onClickModalTab('rate'),
                active: tab === 'rate',
              },
            ],
          },
        },
      }}
    >
      <View value={tripId} title={title} />
    </DynamicContent>
  );
});

TripsModal.displayName = 'TripsModal';

export default TripsModal;
