import { ReactNode, useCallback, useEffect, useState } from 'react';
import { TODAY, TODAY_EOD } from '@/constants';

import { Button } from 'react-bootstrap';
import DateRangePicker from '@/components/DateRangePicker';
import ImportContractProviderDropdown from '@/components/ImportContractProviderDropdown';
// import Input from '@/components/Input';
import QueryFilters from '@/components/QueryFilters';
import { createComponentQueryState } from '@/state';
import { parseQueryString } from '@/utils';
import { useSearchParams } from 'react-router-dom';

// import Input from '@/components/Input';

export type MessageCenterFilters = {
  startDatetime?: string;
  endDatetime?: string;
  userProviderId?: string[];
  flightNumber?: string;
};
export type MessageCenterFiltersProps = { value?: MessageCenterFilters; onSubmit: (values: MessageCenterFilters) => void };

type MessageCenterFiltersState = {
  startDatetime: string;
  endDatetime: string;
  userProviderId: string[];
  flightNumber: string;
};
const initMessageCenterFiltersState: MessageCenterFiltersState = {
  startDatetime: TODAY,
  endDatetime: TODAY_EOD,
  userProviderId: null,
  flightNumber: '',
};

const useFilters = createComponentQueryState(
  window.location.search ? parseQueryString(window.location.search) : initMessageCenterFiltersState
);
export const useMessageCenterFilters = (): [MessageCenterFiltersState, typeof setState, typeof saveState] => {
  const [state, setState, saveState] = useFilters(({ state, setState, saveState }) => [state, setState, saveState]);
  const [params] = useSearchParams();

  useEffect((): void => {
    const searchParamsObject = Object.fromEntries(
      Array.from(params.entries()).map(([key, value]: [string, string]): [string, string] => [key, value || null])
    );
    setState(
      (current: MessageCenterFiltersState): MessageCenterFiltersState => ({
        ...current,
        ...searchParamsObject,
        userProviderId: !searchParamsObject?.['userProviderId'] ? null : [searchParamsObject?.['userProviderId']] || null,
      })
    );
  }, [params, setState]);

  return [state, setState, saveState];
};

const MessageCenterFilters = ({ onSubmit }: MessageCenterFiltersProps): ReactNode => {
  const [state, setState, saveState] = useMessageCenterFilters();
  const { startDatetime, endDatetime, userProviderId, flightNumber: _flightNumber } = state;
  const [lastState, setLastState] = useState<MessageCenterFiltersState>(state);

  const handleReset = useCallback((): void => {
    setState(initMessageCenterFiltersState);
    setLastState(initMessageCenterFiltersState);
    saveState();
    onSubmit(initMessageCenterFiltersState);
  }, [setState, setLastState, saveState, onSubmit]);
  const handleChange = useCallback(
    (name: string): ((value: string | string[]) => void) =>
      (value: string | string[]): void => {
        setState(
          (current: MessageCenterFiltersState): MessageCenterFiltersState => ({
            ...current,
            [name]: name === 'userProviderId' ? (!value ? null : [value]) : value,
          })
        );
      },
    [setState]
  );
  const handleSubmit = useCallback((): void => {
    saveState();
    setLastState(state);
    onSubmit(state);
  }, [state, saveState, onSubmit]);

  useEffect(() => {
    onSubmit(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QueryFilters>
      {/* TODO: This component needs to be deprecated, and replaced with a new one following our new component models. */}
      <QueryFilters.Control>
        <DateRangePicker
          name="dates"
          value={[startDatetime, endDatetime] as any}
          onChange={(event) => {
            const { value } = event?.target || {};
            const [start, end] = value.split(' - ');
            handleChange('startDatetime')(start);
            handleChange('endDatetime')(end);
          }}
          isDirty={lastState.startDatetime !== startDatetime || lastState.endDatetime !== endDatetime}
        />
      </QueryFilters.Control>
      <QueryFilters.Input>
        <ImportContractProviderDropdown
          value={userProviderId}
          onChange={handleChange('userProviderId')}
          options={{ locale: { 'Select...': 'Airline' } }}
          className={
            [lastState.userProviderId || ''].flat()?.[0] !== [userProviderId || ''].flat()?.[0] ? '{border-color:#ff9e16!;}>input' : ''
          }
        />
      </QueryFilters.Input>
      <QueryFilters.Control>
        <Button variant="success" className="border-white" onClick={handleSubmit}>
          GO
        </Button>
        <Button variant="outline-light" onClick={handleReset}>
          Reset
        </Button>
      </QueryFilters.Control>
      {/* <QueryFilters.Input>
        <Input
          type="number"
          value={flightNumber || ''}
          onChange={handleChange('flightNumber')}
          className={(lastState.flightNumber || '') !== (flightNumber || '') ? '{border-color:#ff9e16!;}>input' : ''}
          placeholder="Flight Number"
        />
      </QueryFilters.Input> */}
    </QueryFilters>
  );
};

export default MessageCenterFilters;
