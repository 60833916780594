import { Badge } from 'react-bootstrap';
import { FLAG_DISPLAY_MAX_COUNT } from '@/constants';
import { FlagWithTrip } from '../../../../../models/gen/graphql';
import Tooltip from '@/features/Trips/components/Tooltip';
import { pixelsToRem } from '@/utils/custom';

const BADGE_REM_SIZE = pixelsToRem(4 + FLAG_DISPLAY_MAX_COUNT);

const FlagAndCommunicationCell = ({
  flags,
  hasCommunication,
  onFlagClick,
  onCommunicationClick,
}: {
  flags: FlagWithTrip[];
  hasCommunication: boolean;
  onFlagClick: () => void;
  onCommunicationClick: () => void;
}): React.JSX.Element => (
  <div className="w-100 d-flex justify-content-around">
    <Tooltip content="Flags">
      <div className="{padding-top:0.5rem;}">
        <span className={`FlagsButton ${onFlagClick ? '{cursor:pointer;}' : ''}`} onClick={onFlagClick}>
          {!flags?.length && <i className="sv sv-flag-empty" />}
          {!!flags?.length &&
            flags
              .slice(0, FLAG_DISPLAY_MAX_COUNT)
              .map(
                (flag: FlagWithTrip, f: number): React.JSX.Element => (
                  <i
                    className={`sv sv-flag-filled {left:${pixelsToRem(f * 5)}rem;z-index:${Math.round(FLAG_DISPLAY_MAX_COUNT / (f + 1))};}`}
                    style={{ color: flag?.color }}
                    key={f}
                  />
                )
              )}
          {flags?.length > FLAG_DISPLAY_MAX_COUNT && (
            <Badge className={`{z-index:10;left:${BADGE_REM_SIZE}rem;}`}>+{flags?.length - FLAG_DISPLAY_MAX_COUNT}</Badge>
          )}
        </span>
      </div>
    </Tooltip>
    <Tooltip content="Communications">
      <button name="COMMUNICATIONS_BUTTON" onClick={onCommunicationClick}>
        <i className={`sv sv-message2 fs-5 ${hasCommunication ? 'text-info' : ''}`} />
      </button>
    </Tooltip>
  </div>
);

export default FlagAndCommunicationCell;
