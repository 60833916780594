import './styles.scss';

import { LoadingBlur } from '@/components/LoadingSpinner';
import React, { useRef } from 'react';
import { Trip } from '@/models/gen/graphql';
import TripRow from '@/features/TripsConcept/row';
import { TripsModalTab } from '@/features/TripsConcept/modal';
import useViewport from '@/hooks/useViewport';
import { useVirtualizer } from '@tanstack/react-virtual';

const TripsTable = ({
  trips = [],
  loading = false,
  onEditTrip = () => {},
  onSelect = () => {},
  selected = [],
  virtualize = true,
}: {
  trips: Array<Trip>;
  loading: boolean;
  onEditTrip?: (trip: Trip, tab?: TripsModalTab) => void;
  onSelect?: (selection: string) => void;
  selected?: string[];
  virtualize?: boolean;
}) => {
  const [
    {
      content: { height: viewportHeight = 0 },
    },
  ] = useViewport();
  const parentRef = useRef<HTMLDivElement>(null);
  const tableVirtualizer = useVirtualizer({
    count: trips.length || 0,
    getScrollElement: (): Element => parentRef.current,
    estimateSize: (index: number): number => 45,
    overscan: 20,
  });
  return (
    <>
      <LoadingBlur loading={loading} />
      {virtualize ? (
        <div
          className="TripsTableContainer"
          ref={parentRef}
          style={{
            height: `${viewportHeight}px`,
          }}
        >
          <div
            style={{
              height: `${tableVirtualizer.getTotalSize()}px`,
            }}
          >
            <table className="TripsTable">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" onChange={() => onSelect('all')} />
                  </th>
                  <th>
                    <i className="sv sv-flag-empty fs-5 p-2" />
                    <i className="sv sv-message2 fs-5 p-2" />
                  </th>
                  <th>TYPE</th>
                  <th>DATE</th>
                  <th>SCH</th>
                  <th>ACT</th>
                  <th>CITY</th>
                  <th>AL</th>
                  <th>FLT</th>
                  <th>PLT</th>
                  <th>FA</th>
                  <th>DRV</th>
                  <th>VAN</th>
                  <th>P/U</th>
                  <th>D/O</th>
                  <th>CLT</th>
                  <th>
                    <i className="sv sv-completion fs-5 p-2" />
                  </th>
                  <th>
                    <i className="sv sv-fcr fs-5 p-2" />
                  </th>
                  <th>
                    <i className="sv sv-combine-icon fs-5 p-2" />
                  </th>
                  <th>RATE</th>
                  <th>EDIT</th>
                </tr>
              </thead>
              <tbody>
                {!!trips.length &&
                  tableVirtualizer.getVirtualItems().map((virtualRow, index) => {
                    const trip = trips[virtualRow?.index];
                    return (
                      <TripRow
                        style={{
                          height: `${virtualRow?.size}px`,
                          transform: `translateY(${virtualRow?.start - virtualRow.size * index}px)`,
                        }}
                        key={trip?.id}
                        data={trip}
                        onEditTrip={onEditTrip}
                        onSelect={onSelect}
                        isSelected={selected.length === trips.length}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <table className="TripsTable">
          <thead>
            <tr>
              <th>
                <input type="checkbox" onChange={() => onSelect('all')} />
              </th>
              <th>
                <i className="sv sv-flag-empty fs-5 p-2" />
                <i className="sv sv-message2 fs-5 p-2" />
              </th>
              <th>TYPE</th>
              <th>DATE</th>
              <th>SCH</th>
              <th>ACT</th>
              <th>CITY</th>
              <th>AL</th>
              <th>FLT</th>
              <th>PLT</th>
              <th>FA</th>
              <th>DRV</th>
              <th>VAN</th>
              <th>P/U</th>
              <th>D/O</th>
              <th>CLT</th>
              <th>
                <i className="sv sv-completion fs-5 p-2" />
              </th>
              <th>
                <i className="sv sv-fcr fs-5 p-2" />
              </th>
              <th>
                <i className="sv sv-combine-icon fs-5 p-2" />
              </th>
              <th>RATE</th>
              <th>EDIT</th>
            </tr>
          </thead>
          <tbody>
            {!!trips.length &&
              trips.map((trip, index) => {
                return (
                  <TripRow
                    key={trip?.id}
                    data={trip}
                    onEditTrip={onEditTrip}
                    onSelect={onSelect}
                    isSelected={selected.length === trips.length}
                  />
                );
              })}
          </tbody>
        </table>
      )}

      {!trips.length && !loading && (
        <tr>
          <td colSpan={20}>No trips found</td>
        </tr>
      )}
    </>
  );
};

export default React.memo(TripsTable);
