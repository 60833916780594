import { StateStorage, createJSONStorage, persist } from 'zustand/middleware';
import { StoreApi, UseBoundStore, create } from 'zustand';
import { generateQueryString, getResultFromState, parseQueryString } from '@/utils';

type ComponentStateType<T> = {
  state: T;
  setState: (value: T | ((current: T) => T)) => void;
};

export type InitComponentStateType<T> =
  | T
  | ((setState: (value: T | ((current: T) => T)) => void, getState: () => ComponentStateType<T>) => T | [T, Record<string, Function>]);

export const getInitState = <T>(initState, set, get) => {
  const setState = (val: T | ((current: T) => T)): void =>
    set((current: ComponentStateType<T>): ComponentStateType<T> => ({ ...current, state: getResultFromState(val, current.state) }));
  let state: T;
  let methods: Record<string, Function> = {};
  if (typeof initState === 'function') {
    const result = (initState as Function)(setState, get);
    if (Array.isArray(result)) {
      [state, methods] = result;
    } else {
      state = result;
    }
  } else {
    state = initState;
  }
  return {
    state,
    ...methods,
    setState,
  };
};

export const createComponentState = <T>(initState: InitComponentStateType<T>): UseBoundStore<StoreApi<ComponentStateType<T>>> =>
  create<ComponentStateType<T>>((set, get): ComponentStateType<T> => getInitState(initState, set, get));

export const createPersistentComponentState = <T>(name: string, initState: T) =>
  create<ComponentStateType<T>>()(
    persist((set, get) => getInitState(initState, set, get), {
      name,
      storage: createJSONStorage(() => localStorage),
    })
  );

const saveSearchStorage = (state) => {
  const parsed = Object.entries(state).reduce(
    (acc, [key, value]: [string, unknown]) =>
      value === undefined ? { ...acc, [key]: '' } : { ...acc, [key]: Array.isArray(value) ? value.join(',') : value },
    {}
  );
  window.history.pushState(
    {},
    '',
    `${window.location.pathname}?${generateQueryString(parsed)
      .replace(/=undefined/, '')
      .replace(/=&/, '&')
      .replace(/=$/, '')}`
  );
};
const getSearchStorage = (initState) => {
  const parsed = { ...initState, ...parseQueryString(window.location.search) };
  const state = Object.entries(parsed).reduce(
    (acc, [key, value]: [string, string]) =>
      value === 'undefined' ? { ...acc, [key]: '' } : { ...acc, [key]: value.includes(',') ? value.split(',') : value },
    {}
  );
  return JSON.stringify({ state: { state } });
};
const searchStorage = (initState, live: boolean = false): StateStorage => ({
  getItem: (): string => getSearchStorage(initState),
  setItem: (_key: string, value: string): void => {
    if (!live) return;
    const {
      state: { state },
    } = JSON.parse(value);
    saveSearchStorage(state);
  },
  removeItem: (): void => {
    window.history.replaceState({}, '', `${window.location.pathname}`);
  },
});

export const createComponentQueryState = <T>(initState: T, options?: { live?: boolean }) =>
  create<ComponentStateType<T> & { saveState: () => void }>()(
    persist(
      (set, get) => ({
        ...getInitState(initState, set, get),
        saveState: () => {
          const { state } = get();
          saveSearchStorage(state);
        },
      }),
      {
        name: 'query',
        storage: createJSONStorage(() => searchStorage(initState, options?.live === true)),
      }
    )
  );
