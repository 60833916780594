import {
  ConnectionDetails,
  QueryInputType,
  convertConnectionToDetails,
  handleError,
  mergeConnectionDetails,
  queryInput,
} from '../../../utils/custom';
import {
  GetAvailabilityCountDocument,
  GetDriverDetailsDocument,
  SearchUserTableDocument,
  SearchUsersDocument,
  SearchUsersWithAvailabilityDocument,
  SelectUserFilterDocument,
} from '../../queries';
import { GraphApiMethodOptions, GraphApiResponse } from '../../core';
import {
  User,
  UserAvailability,
  UserAvailabilityWithDatetimesSearch,
  UserConnection,
  UserConnectionInput,
  UserEdge,
  UserSearch,
  UserWithAirportAppendAvailabilityConnectionInput,
  UserWithAirportConnectionInput,
} from '../../../models/gen/graphql';

import { Datetime } from '../../../utils/dates';
import Logger from '../../../utils/logs';
import { appState } from '../../../store/appReducer';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

const log = Logger.of('searchUsers');

// types
type SearchUsersGraphApiResponse = GraphApiResponse<typeof SearchUsersDocument>;
type SearchUsersWithAvailabilityGraphApiResponse = GraphApiResponse<typeof SearchUsersWithAvailabilityDocument>;
type GetAvailabilityRequestCountGraphApiResponse = GraphApiResponse<typeof GetAvailabilityCountDocument>;
type SearchUserTableGraphApiResponse = GraphApiResponse<typeof SearchUserTableDocument>;
type GetDriverDetailsGraphApiResponse = GraphApiResponse<typeof GetDriverDetailsDocument>;
type SelectUserFilterGraphApiResponse = GraphApiResponse<typeof SelectUserFilterDocument>;

type SearchUsersOptions = { pageSize?: number; page?: number; merge?: boolean };

export type UserWithAvailabilitySearch = {
  query?: UserSearch[];
  availabilityQuery?: UserAvailabilityWithDatetimesSearch[];
  sortDate?: string;
  startDatetime?: string;
  endDatetime?: string;
  airportCodes?: string[];
};

type UserTableSearch = {
  query?: UserSearch[];
  airports?: string[];
};

// constants
export const SEARCH_USERS_PAGE_SIZE = 1000;
export const SEARCH_USERS_TABLE_PAGE_SIZE = 1000;

// helper funcs
const searchUsersResponseSelector = (res: SearchUsersGraphApiResponse): ConnectionDetails<User> =>
  convertConnectionToDetails(res?.searchUsers?.userConnection as UserConnection);

const searchUsersWithAvailabilityResponseSelector = (res: SearchUsersWithAvailabilityGraphApiResponse): ConnectionDetails<User> =>
  convertConnectionToDetails(res?.searchUsers?.userWithAirportAppendAvailabilityConnection as UserConnection);
const getAvailabilityRequestCountResponseSelector = (res: GetAvailabilityRequestCountGraphApiResponse): number =>
  res?.searchUsers?.userWithAirportAppendAvailabilityConnection?.edges
    ?.map(({ node: { availability } }: UserEdge): UserAvailability[] => availability || [])
    .flat().length || 0;

const searchUserTableResponseSelector = (res: SearchUserTableGraphApiResponse): ConnectionDetails<User> =>
  convertConnectionToDetails(res?.searchUsers?.userWithAirportConnection as UserConnection);

const getDriverDetailsResponseSelector = (res: GetDriverDetailsGraphApiResponse): User[] =>
  res?.searchUsers?.userConnection?.edges?.map((edge: UserEdge): User => edge?.node);

const selectUserFilterResponseSelector = (res: SelectUserFilterGraphApiResponse): Partial<User>[] =>
  res?.searchUsers?.userWithAirportConnection?.edges?.map((edge) => edge?.node);

// queries
const [runSearchUsers, runRefetchSearchUsers] = graphApi(SearchUsersDocument, {
  onError: (res: SearchUsersGraphApiResponse): void => handleError(res, { notification: { title: 'Search Users' } }),
});
const [runSearchUsersWithAvailability, runRefetchSearchUsersWithAvailability] = graphApi(SearchUsersWithAvailabilityDocument, {
  onError: (res: SearchUsersWithAvailabilityGraphApiResponse): void =>
    handleError(res, { notification: { title: 'Search Users Availability' } }),
});
const [runGetAvailabilityCount, runRefetchGetAvailabilityCount] = graphApi(GetAvailabilityCountDocument, {
  onError: (res: SearchUsersWithAvailabilityGraphApiResponse): void =>
    handleError(res, { notification: { title: 'Get Availability Requests' } }),
});
const [runSearchUserTable, runRefetchSearchUserTable] = graphApi(SearchUserTableDocument, {
  onError: (res: SearchUserTableGraphApiResponse): void => handleError(res, { notification: { title: 'Search Users Table' } }),
});
const [runGetDriverDetails, runRefetchGetDriverDetails] = graphApi(GetDriverDetailsDocument);
const [runSelectUserFilter, runRefetchSelectUserFilter] = graphApi(SelectUserFilterDocument);

// api
const searchUsers = async (query?: UserSearch[], options: SearchUsersOptions = {}): Promise<ConnectionDetails<User>> => {
  const { pageSize = SEARCH_USERS_PAGE_SIZE, page = 0, merge = false } = options;

  const input: UserConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions: GraphApiMethodOptions = { merge: merge ? 'searchUsers.userConnection' : undefined };

  const res = await runSearchUsers({ input }, graphApiOptions);

  return searchUsersResponseSelector(res);
};

const refetchSearchUsers = async (query?: UserSearch[], options: SearchUsersOptions = {}): Promise<ConnectionDetails<User>> => {
  const { pageSize = SEARCH_USERS_PAGE_SIZE, page = 0, merge = false } = options;

  const input: UserConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
  };
  const graphApiOptions: GraphApiMethodOptions = { merge: merge ? 'searchUsers.userConnection' : undefined };

  const res = await runRefetchSearchUsers({ input }, graphApiOptions);

  return searchUsersResponseSelector(res);
};

const searchUsersWithAvailability = async (
  search: UserWithAvailabilitySearch = {},
  options: SearchUsersOptions = {}
): Promise<ConnectionDetails<User>> => {
  const { pageSize, page = 0, merge = false } = options;
  const { query = [], availabilityQuery = [], sortDate, startDatetime, endDatetime, airportCodes } = search;

  const input: UserWithAirportAppendAvailabilityConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
    availabilityQuery,
    airportCodes,
    sortDate,
    startDatetime,
    endDatetime,
  };
  const graphApiOptions: GraphApiMethodOptions = { merge: merge ? 'searchUsers.userWithAirportAppendAvailabilityConnection' : undefined };

  const res = await runSearchUsersWithAvailability({ input }, graphApiOptions);

  return searchUsersWithAvailabilityResponseSelector(res);
};

const refetchSearchUsersWithAvailability = async (
  search: UserWithAvailabilitySearch = {},
  options: SearchUsersOptions = {}
): Promise<ConnectionDetails<User>> => {
  const { pageSize, page = 0, merge = false } = options;
  const { query, availabilityQuery, sortDate, startDatetime, endDatetime, airportCodes } = search;

  const input: UserWithAirportAppendAvailabilityConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
    availabilityQuery,
    airportCodes,
    sortDate,
    startDatetime,
    endDatetime,
  };
  const graphApiOptions: GraphApiMethodOptions = { merge: merge ? 'searchUsers.userWithAirportAppendAvailabilityConnection' : undefined };

  const res = await runRefetchSearchUsersWithAvailability({ input }, graphApiOptions);

  return searchUsersWithAvailabilityResponseSelector(res);
};

const getAvailabilityRequestCount = async (): Promise<number> => {
  const [{ config }] = appState();
  const driverRoleId = config?.driverRoleId || '';
  const currentDatetime = new Datetime().startOf('day');

  const input: UserWithAirportAppendAvailabilityConnectionInput = {
    first: null,
    after: null,
    query: [{ roleId: queryInput(driverRoleId) }],
    availabilityQuery: [{ approved: queryInput([], QueryInputType.ISNULL) }],
    sortDate: currentDatetime.dateInput,
    startDatetime: currentDatetime.toString(),
    endDatetime: currentDatetime.clone().add(2, 'months').endOf('day').toString(),
  };
  const res = await runRefetchGetAvailabilityCount({ input });
  return getAvailabilityRequestCountResponseSelector(res);
};

const searchUserTable = async (search?: UserTableSearch, options: SearchUsersOptions = {}): Promise<ConnectionDetails<User>> => {
  const { pageSize = SEARCH_USERS_PAGE_SIZE, page = 0, merge = false } = options;
  const { query, airports = [] } = search;

  const input: UserWithAirportConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
    airports,
  };
  const graphApiOptions: GraphApiMethodOptions = { merge: merge ? 'searchUsers.userWithAirportConnection' : undefined };

  const res = await runSearchUserTable({ input }, graphApiOptions);

  return searchUserTableResponseSelector(res);
};

const refetchSearchUserTable = async (search: UserTableSearch = {}, options: SearchUsersOptions = {}): Promise<ConnectionDetails<User>> => {
  const { pageSize = SEARCH_USERS_PAGE_SIZE, page = 0, merge = false } = options;
  const { query, airports = [] } = search;

  const input: UserWithAirportConnectionInput = {
    first: pageSize || null,
    after: pageSize ? (pageSize * page).toString() : null,
    query,
    airports,
  };
  const graphApiOptions: GraphApiMethodOptions = { merge: merge ? 'searchUsers.userWithAirportConnection' : undefined };

  const res = await runRefetchSearchUserTable({ input }, graphApiOptions);

  return searchUserTableResponseSelector(res);
};

const getDriverDetails = async (driverIds: string[]): Promise<User[]> => {
  try {
    if (!driverIds.length) throw new Error('Failed to search users: driverIds not provided.');

    const input: UserConnectionInput = {
      query: [
        {
          id: queryInput(driverIds),
        },
      ],
    };

    const res = await runRefetchGetDriverDetails({ input });

    return getDriverDetailsResponseSelector(res);
  } catch (err) {
    const message = err?.message || err;
    log.error('getDriverDetails', message);
  }
};

export const getDriverById = async (driverId: string): Promise<User> => {
  try {
    if (!driverId) throw new Error('Failed to search users: driverId not provided.');
    const res = await getDriverDetails([driverId]);
    return res[0];
  } catch (err) {
    const message = err?.message || err;
    log.error('getDriverById', message);
  }
};

export const getSelectUserFilter = async (airports: string[] = [], ...query: UserSearch[]): Promise<Partial<User>[]> => {
  try {
    const res = await runRefetchSelectUserFilter({
      input: {
        airports,
        query,
      },
    });
    return selectUserFilterResponseSelector(res);
  } catch (err) {
    const message = err?.message || err;
    log.error('getSelectUserFilter', message);
  }
};

// hooks
export const useSearchUsers = createGraphApiHook(searchUsers, { refetch: refetchSearchUsers, merge: mergeConnectionDetails });
export const useSearchUsersWithAvailability = createGraphApiHook(searchUsersWithAvailability, {
  refetch: refetchSearchUsersWithAvailability,
  merge: mergeConnectionDetails,
});
export const useSearchUserTable = createGraphApiHook(searchUserTable, { refetch: refetchSearchUserTable, merge: mergeConnectionDetails });
export const useGetAvailabilityRequestCount = createGraphApiHook(getAvailabilityRequestCount);
