import { Button, Modal } from 'react-bootstrap';
import { Completion, SortDirectionEnum, Trip, TripStatusEnum, TripTableFormatEnum, TripTableSearch } from '@/models/gen/graphql';
import { ConnectionDetails, QueryInputType, queryInput } from '@/utils/custom';
import { SEARCH_TRIPS_TABLE_PAGE_SIZE, useSearchTripsTable } from '@/api/services/trips/searchTrips';
import TripFilters, { TripFiltersRefMethods, TripsFiltersState, initTripsFiltersState } from '@/features/Trips/components/TripFilters';
import { useCallback, useMemo, useRef, useState } from 'react';
import useTripTableState, { DEFAULT_TRIP_SORTING, TripSortColumnEnum } from '@/features/Trips/components/TripsTable/hook';

import DeleteTripsModal from '@/pages/Trips/components/DeleteTripsModal';
import FormButton from '@/components/FormButton';
import HtmlTripsTable from '@/features/Trips/components/HtmlTripsTable';
import PageInfo from '@/components/PageInfo';
import { Validation } from '@/utils/validations';
import deleteTripBulk from '@/api/services/trips/deleteTripBulk';
import equal from 'fast-deep-equal/es6/react';
import useConfirmation from '@/hooks/useConfirmation';
import useModal from '@/hooks/useModal';
import { zeroPadFlightNumber } from '@/utils/numbers';

const HtmlTripPage = (): React.JSX.Element => {
  // queries
  const [{ data: { rows = [], totalCount = 0 } = {}, loading }, { fetch, refetch, setData }] = useSearchTripsTable();
  const [
    { data: { rows: priorityRows = [] } = {}, loading: loadingPriority },
    { fetch: fetchPriority, refetch: refetchPriority, setData: setPriorityData },
  ] = useSearchTripsTable();
  // state
  const setState = useTripTableState(({ setState }) => setState);
  const onSetTrips = useTripTableState(({ state }) => state.onSetTrips);
  const getSelectedTrips = useTripTableState(({ state }) => state.getSelectedTrips);
  const sorting = useTripTableState(({ state }) => state.sorting);
  const onDeleteRow = useTripTableState(({ state }) => state.onDeleteRow);
  const onSelectAll = useTripTableState(({ state }) => state.onSelectAll);

  const [search, setSearch] = useState<string>(initTripsFiltersState.search);
  const [lastFormat, setLastFormat] = useState<TripTableFormatEnum>(initTripsFiltersState.format);
  const [showDeleteTripsModal, setShowDeleteTripsModal] = useState<boolean>(false);
  //ref
  const lastFilters = useRef<TripsFiltersState>(initTripsFiltersState);
  const tripFiltersRef = useRef<TripFiltersRefMethods>(null);

  const onFilterSubmit = useCallback(
    async (filters: TripsFiltersState): Promise<void> => {
      // refetch if filters didn't changed
      if (equal(filters, lastFilters.current)) return handleRefetch();

      const tripSearch = convertTripsFiltersStateToQuery(filters, sorting);
      // fetch priority trips if format is current
      if (filters.format === TripTableFormatEnum.Current) {
        fetchPriority({ query: [tripSearch], format: TripTableFormatEnum.Priority }, { pageSize: SEARCH_TRIPS_TABLE_PAGE_SIZE });
      }
      // fetch trips table
      fetch({ query: [tripSearch], format: filters.format }, { pageSize: SEARCH_TRIPS_TABLE_PAGE_SIZE });
      lastFilters.current = filters;
      setLastFormat(filters.format);
      // clear selected state on search
      setState((current) => ({
        ...current,
        selected: new Map(),
      }));
    },
    [fetch, fetchPriority, lastFormat, sorting]
  );
  const handleRefetch = useCallback(async (): Promise<void> => {
    if (lastFormat === TripTableFormatEnum.Current) refetchPriority();
    refetch();
    // clear selected state on search
    setState((current) => ({
      ...current,
      selected: new Map(),
    }));
  }, [refetch, refetchPriority, lastFormat, sorting]);
  const onReset = async (input: TripsFiltersState): Promise<void> => {
    setState((current) => ({
      ...current,
      sorting: DEFAULT_TRIP_SORTING,
    }));
    setSearch(input.search);
    onFilterSubmit(input);
  };

  // confirmation
  const confirmIllegalCombines = useConfirmation({
    Header: {
      as: ({ data }) => (
        <div className="d-flex w-100 justify-content-start align-items-center gap-2 border-bottom border-1 pb-3 fs-3 mb-4">
          <i className="sv sv-warning text-danger" />
          <div>ILLEGAL COMBINE - {data?.title}</div>
        </div>
      ),
    },
    Footer: {
      as: ({ onResolve, onReject }) => (
        <Modal.Footer className="d-flex justify-content-end mt-3">
          <div>
            <Button name="REJECT" className="flex-grow-1 px-4" variant="secondary" onClick={onReject}>
              CANCEL
            </Button>
          </div>
          <div>
            <Button name="RESOLVE" className="flex-grow-1 px-4" variant="danger" onClick={onResolve}>
              DO IT ANYWAY
            </Button>
          </div>
        </Modal.Footer>
      ),
    },
  });

  // modals
  const [, { show: showEditTripsModal }] = useModal('EditTrips', { onSubmit: handleRefetch });
  const [, { show: showEditCompletionModal }] = useModal('EditCompletion', { onSubmit: handleRefetch });
  const [, { show: showEditFcrModal }] = useModal('EditFcr', { onSubmit: handleRefetch });
  const [, { show: showEditCombineModal }] = useModal('EditCombine', { onSubmit: handleRefetch });
  const [, { show: showEditFlagModal }] = useModal('EditFlag', { onSubmit: handleRefetch });
  const [, { show: showEditCommunicationModal }] = useModal('EditCommunication', { onSubmit: handleRefetch });
  const [, { show: showRateReportModal }] = useModal('RateReport', { onSubmit: handleRefetch });

  const onEditTrip = useCallback((data: Partial<Trip> | string, edit?: boolean): void => {
    const isEditing = edit ?? (typeof data === 'string' ? !!data : !!data?.id);
    const selected = isEditing ? getSelectedTrips() : [];
    showEditTripsModal({
      trip: edit ? selected[0] : data,
      selected,
      creating: !isEditing,
      editing: isEditing,
    });
  }, []);
  const onEditFlag = useCallback(
    (id: string, servicerIataAirlineCode: string, flightNumber: string, scheduled: string): void =>
      showEditFlagModal({
        tripId: id,
        servicerIataAirlineCode: servicerIataAirlineCode,
        flightNumber: flightNumber,
        scheduled: scheduled,
      }),
    []
  );
  const onEditCommunication = useCallback(
    (id: string, servicerIataAirlineCode: string, flightNumber: string, scheduled: string, offset: string): void =>
      showEditCommunicationModal({
        tripId: id,
        offset: offset,
        servicerIataAirlineCode: servicerIataAirlineCode,
        flightNumber: flightNumber,
        scheduled: scheduled,
      }),
    []
  );
  const onEditCompletion = useCallback(
    (
      id: string,
      servicerIataAirlineCode: string,
      flightNumber: string,
      scheduled: string,
      completion: Completion,
      completionId: string
    ): void =>
      showEditCompletionModal({
        tripId: id,
        completion: completion,
        completionId: completionId,
        scheduled: scheduled,
        servicerIataAirlineCode: servicerIataAirlineCode,
        flightNumber: flightNumber,
      }),
    []
  );
  const onEditFcr = useCallback(
    (id: string, servicerIataAirlineCode: string, flightNumber: string, scheduled: string): void =>
      showEditFcrModal({
        tripId: id,
        servicerIataAirlineCode: servicerIataAirlineCode,
        flightNumber: flightNumber,
        scheduled: scheduled,
      }),
    []
  );
  const onEditCombine = useCallback(
    (id: string, servicerIataAirlineCode: string, flightNumber: string, scheduled: string, combineId: string): void =>
      showEditCombineModal({
        servicerIataAirlineCode: servicerIataAirlineCode,
        flightNumber: flightNumber,
        scheduled: scheduled,
        combineId: combineId,
        tripId: id,
      }),
    []
  );
  const onEditRateReport = useCallback(
    (selected: string[]): void =>
      showRateReportModal({
        tripIds: selected,
      }),
    []
  );

  const onDeleteTrips = useCallback(
    (tripIds: string[], format: TripTableFormatEnum): void => {
      // we will only strike though the trip if the format is ALL or DELETED otherwise we will remove the trip from state
      // and clear selected trips
      const softDelete = [TripTableFormatEnum.All, TripTableFormatEnum.Deleted].includes(format);
      const idsToUpdate = new Set(tripIds);
      // update zustand trips table state
      onDeleteRow(idsToUpdate, softDelete);
      if (softDelete) return;
      // if the format is not current update the query data state and remove the trips
      const deleteTripsFromData = (current: ConnectionDetails<Trip>): ConnectionDetails<Trip> => {
        const rows = [];
        for (const row of current.rows) {
          if (idsToUpdate.has(row.id)) continue;
          rows.push(row);
        }
        return { ...current, rows };
      };

      setData(deleteTripsFromData);
      setPriorityData(deleteTripsFromData);
    },
    [setState]
  );

  const handleDelete = useCallback(
    async (values: any, comment: string, selected: Trip[]): Promise<void> => {
      const selectedTripIds = selected.map((trip: Trip): string => trip.id);
      // call delete
      const res = await deleteTripBulk(comment, selectedTripIds);
      if (!res) return; // no deletes
      // update state
      onDeleteTrips(selectedTripIds, lastFormat);
    },
    [onDeleteTrips, lastFormat]
  );

  // FE search
  const onSearch = (val: string): void => setSearch(val);

  const [tripIds, priorityTripIds] = useMemo((): [string[], string[]] => {
    const tripIds: Map<string, Trip> = !rows?.length ? new Map() : searchTripsTableColumns(rows, search);
    const priorityTripIds: Map<string, Trip> = !priorityRows?.length ? new Map() : searchTripsTableColumns(priorityRows, search);

    onSetTrips([...tripIds.values(), ...priorityTripIds.values()]);
    return [Array.from(tripIds.keys()), Array.from(priorityTripIds.keys())];
  }, [rows, priorityRows, search, lastFormat, onSetTrips]);

  const Shortcuts = useMemo(
    (): React.JSX.Element => (
      <TripsTableShortcuts
        deleteAll={(): void => {
          onSelectAll();
          setShowDeleteTripsModal(true);
        }}
        uaCancels={(): void => {
          tripFiltersRef.current.quickFilter(
            (current: TripsFiltersState): TripsFiltersState => ({ ...current, format: TripTableFormatEnum.United })
          );
        }}
      />
    ),
    []
  );

  return (
    <>
      <TripFilters
        onSubmit={onFilterSubmit}
        onReset={onReset}
        onSearch={onSearch}
        sorting={sorting}
        ref={tripFiltersRef}
        onDelete={(): void => setShowDeleteTripsModal(true)}
        onCreate={(): void => onEditTrip({ status: TripStatusEnum.Active })}
        onEdit={(): void => onEditTrip(undefined, true)}
      />
      <PageInfo>
        Total Trips: {tripIds.length} / {totalCount}
      </PageInfo>
      {(loading || loadingPriority) && (
        <PageInfo>
          <i className="fa fa-spinner fa-pulse" />
        </PageInfo>
      )}
      {lastFormat === TripTableFormatEnum.Current && (
        <div className="PriorityTrips mb-3">
          <HtmlTripsTable
            title="Late Outbound / Flagged Trips"
            rows={priorityTripIds}
            loading={loadingPriority}
            onEditTrip={onEditTrip}
            onEditFlag={onEditFlag}
            onEditCommunication={onEditCommunication}
            onEditCompletion={onEditCompletion}
            onEditFcr={onEditFcr}
            onEditCombine={onEditCombine}
            onEditRateReport={onEditRateReport}
            confirmIllegalCombines={confirmIllegalCombines}
          />
        </div>
      )}
      <div className="Trips">
        <HtmlTripsTable
          title="All Trips"
          shortcuts={Shortcuts}
          rows={tripIds}
          loading={loading}
          onEditTrip={onEditTrip}
          onEditFlag={onEditFlag}
          onEditCommunication={onEditCommunication}
          onEditCompletion={onEditCompletion}
          onEditFcr={onEditFcr}
          onEditCombine={onEditCombine}
          onEditRateReport={onEditRateReport}
          confirmIllegalCombines={confirmIllegalCombines}
        />
      </div>
      <DeleteTripsModal
        show={showDeleteTripsModal}
        onHide={(): void => setShowDeleteTripsModal(false)}
        selected={showDeleteTripsModal ? getSelectedTrips().filter((trip: Trip): boolean => !trip.deletedAt) : null}
        formValues={{}}
        onDelete={handleDelete}
      />
    </>
  );
};

const TripsTableShortcuts = ({ deleteAll, uaCancels }: { deleteAll: () => void; uaCancels: () => void }): React.JSX.Element => {
  return (
    <>
      <FormButton variant="icon" onClick={deleteAll} icon={<i className="sv sv-trash2" />}>
        Delete All
      </FormButton>
      <FormButton variant="icon" onClick={uaCancels}>
        UA Cancels
      </FormButton>
    </>
  );
};

// takes the rows and search and returns the trips ids that match
const searchTripsTableColumns = (rows: Trip[], search: string): Map<string, Trip> => {
  const output: Map<string, Trip> = new Map();
  const expression = new RegExp(search, 'gi');
  for (let i = 0; i < rows.length; i++) {
    const node = rows[i];
    // if nothing to search then add all
    if (!search) {
      output.set(node.id, node);
      continue;
    }
    // if something to search then add only if search matches
    // limit search to searchable columns
    const searchableColumns = [
      node.type,
      node.scheduled,
      node.trackFlight ?? '',
      node.kind,
      node.airportCode,
      node.servicerIataAirlineCode ?? '',
      zeroPadFlightNumber(node.flightNumber),
      node.pilots,
      node.attendants,
      node.driver?.employeeId ?? '',
      node.driver?.fullName ?? '',
      node.puLocation?.name ?? '',
      node.doLocation?.name ?? '',
      node.rate?.rate ?? '',
      node.vehicle?.trackingId ?? '',
      node.payerProvider?.displayName ?? '',
    ].join(' ');

    const match = !!searchableColumns.match(expression);
    if (!match) continue;
    output.set(node.id, node);
  }
  return output;
};

const convertTripsFiltersStateToQuery = (
  filters: TripsFiltersState,
  sorting: Array<{ column: TripSortColumnEnum; direction: SortDirectionEnum }>
): TripTableSearch => {
  // default query
  const query: TripTableSearch = {
    latestScheduled: queryInput.date([filters.from, filters.to]),
  };
  // destructure whatever keys don't map to TripTableSearch
  const { search, format, from, to, ...remainingFilters } = filters || {};
  // loop through rest of the filters
  for (let key in remainingFilters) {
    if (!Validation.isTruthy(remainingFilters[key])) continue;
    // if there is a value, add it to the query

    const { value, type } = convertValueToQueryInput(remainingFilters[key]);
    query[key] = queryInput(value, type);
  }

  // apply sorting
  const output = applySortingToTripQuery(query, sorting);

  return output;
};

const convertValueToQueryInput = (value: any): { type: QueryInputType; value: any } => {
  let type = QueryInputType.OR;
  switch (value) {
    case null:
    case 'NO_RATE':
      type = QueryInputType.ISNULL;
      value = [];
      break;
  }
  return { type, value };
};

const applySortingToTripQuery = (
  query: TripTableSearch,
  sorting: Array<{ column: TripSortColumnEnum; direction: SortDirectionEnum }>
): TripTableSearch => {
  if (!sorting?.length) return query;
  const output = { ...query };

  for (let i = 0; i < sorting.length; i++) {
    const { column, direction } = sorting[i];
    if (!direction) continue;
    const { values = [], type = QueryInputType.DEFAULT } = output[column] || {};
    output[column] = queryInput(values, type, direction, i);
  }

  return output;
};

export default HtmlTripPage;
