import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import ReactDOM from 'react-dom';

interface TooltipProps {
  children: ReactNode;
  content: ReactNode;
}

// Memoize Tooltip component to prevent unnecessary re-renders
const Tooltip: React.FC<TooltipProps> = ({ children, content }) => {
  const [visible, setVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  // Throttle the tooltip positioning to avoid excessive recalculations
  const updateTooltipPosition = useCallback((event: React.MouseEvent<HTMLDivElement> | React.FocusEvent<HTMLDivElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setTooltipPosition({
      top: rect.top + window.scrollY - 10,
      left: rect.left + window.scrollX + rect.width / 2,
    });
  }, []);

  // Show tooltip on hover or focus
  const handleShowTooltip = useCallback(
    (event: React.MouseEvent<HTMLDivElement> | React.FocusEvent<HTMLDivElement>) => {
      updateTooltipPosition(event);
      setVisible(true);
    },
    [updateTooltipPosition]
  );

  // Hide tooltip on mouse out or blur
  const handleHideTooltip = useCallback(() => {
    setVisible(false);
  }, []);

  const tooltipContent = useMemo(
    () =>
      visible &&
      ReactDOM.createPortal(
        <div
          style={{
            position: 'absolute',
            top: `${tooltipPosition.top}px`,
            left: `${tooltipPosition.left}px`,
            transform: 'translate(-50%, -100%)',
            backgroundColor: '#fff',
            color: '#333',
            padding: '8px',
            borderRadius: '4px',
            whiteSpace: 'nowrap',
            fontSize: '10px',
            zIndex: 1000,
            willChange: 'transform, opacity', // for better rendering
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          {content}
          <div
            style={{
              position: 'absolute',
              bottom: '-5px', // Position the arrow 5px below the tooltip
              left: '50%',
              transform: 'translateX(-50%)',
              width: 0,
              height: 0,
              borderLeft: '5px solid transparent',
              borderRight: '5px solid transparent',
              borderTop: '5px solid #fff',
            }}
          ></div>
        </div>,
        document.body
      ),
    [visible, tooltipPosition, content]
  );

  return (
    <div
      onMouseOver={handleShowTooltip}
      onMouseOut={handleHideTooltip}
      onFocus={handleShowTooltip}
      onBlur={handleHideTooltip}
      style={{ position: 'relative', display: 'inline-block' }}
    >
      {children}
      {tooltipContent}
    </div>
  );
};

export default React.memo(Tooltip);
