import { handleError, parsePhoneNumber, titleCase } from '@/utils';
import { useCallback, useState } from 'react';

import { AssignVehicleDropdown } from '@/components/VehicleDropdown';
import { Button } from 'react-bootstrap';
import { RunAssignVehicleToDriverInput } from '@/models/gen/graphql';
import Tooltip from '@/features/Trips/components/Tooltip';
import assignVehicleToDriverBulk from '@/api/services/vehicles/assignVehicleToDriver';
import useTripTableState from '@/features/Trips/components/TripsTable/hook';

const AssignVehicleCell = ({
  actual,
  scheduled,
  airportCode,
  driverId,
  rowId,
}: {
  actual: string;
  scheduled: string;
  airportCode: string;
  driverId: string;
  rowId: string;
}): React.JSX.Element => {
  const [data, refetch] = useTripTableState(({ state }) => {
    const trip = state.trips[rowId];
    return [
      {
        id: trip?.id,
        vehicle: trip?.vehicle,
        vehicleId: trip?.vehicleId,
      },
      state.refetch,
    ];
  });

  const [value, setValue] = useState(data.vehicleId || '');
  const [editing, setEditing] = useState(false);

  const handleChange = useCallback(
    async (value: string): Promise<void> => {
      try {
        if (!value || value === data.vehicleId) return;
        if (!rowId) throw new Error('No trip found.');
        setValue(value);
        const input: RunAssignVehicleToDriverInput = {
          userId: driverId || '',
          vehicleId: value,
          tripId: rowId || null,
          scheduled: scheduled || null,
        };
        await assignVehicleToDriverBulk(input);
        refetch();
      } catch (err) {
        handleError(err, { notification: { title: 'Assign Vehicle To Trip' } });
        setValue(data.vehicleId || '');
      }
    },
    [data.vehicleId, rowId, driverId, scheduled, refetch]
  );
  return (
    <Tooltip
      content={
        <>
          <div className="text-center">{`Vehicle #${data.vehicle?.trackingId || '--'}`}</div>
          <div>{`Phone: ${parsePhoneNumber(data.vehicle?.phoneNumber || '', ' ')}`}</div>
          <div className="text-center">{`Capacity: ${data.vehicle?.capacity || '--'}`}</div>
          <div className="text-center">{titleCase(`${data.vehicle?.make || ''} ${data.vehicle?.model || ''}`)}</div>
        </>
      }
    >
      <div>
        {editing && (
          <AssignVehicleDropdown
            name="vehicle"
            value={value}
            className="DropdownCell text-center {padding-left:0;padding-right:0!;}>input"
            onChange={(val) => {
              handleChange(val);
              setEditing(false);
            }}
            airportCode={airportCode}
            scheduled={scheduled}
            actual={actual}
            details={{
              id: data.vehicle?.id,
              trackingId: data.vehicle?.trackingId,
            }}
            options={{
              onToggle: setEditing,
              lazyLoadItems: true,
              openOnMount: true,
              showLoadingSpinner: false,
              showRefreshButton: false,
              showChevron: false,
              showClearButton: false,
              autoSelect: false,
              disabled: !driverId,
              locale: { 'Select...': data.vehicle?.trackingId || '--' },
            }}
          />
        )}
        {!editing && (
          <Button variant="icon" className="w-100 p-0 text-center" onClick={() => setEditing(true)}>
            {data.vehicle?.trackingId || '--'}
          </Button>
        )}
      </div>
    </Tooltip>
  );
};

export default AssignVehicleCell;
