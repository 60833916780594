import AssignDriverCell from '@/features/Trips/components/TripsTable/Cells/AssignDriverCell';
import AssignVehicleCell from '@/features/Trips/components/TripsTable/Cells/AssignVehicleCell';
import RateCell from '@/features/Trips/components/TripsTable/Cells/RateCell';
import Tooltip from '@/features/TripsConcept/tooltip';
import { Trip } from '@/models/gen/graphql';
import { TripsModalTab } from '@/features/TripsConcept/modal';
import { getClasses } from '@/utils';
import { useTripFilters } from '@/features/TripsConcept/filters';
import { CSSProperties } from 'react';

type TripRowProps = {
  data: Trip;
  onEditTrip: (trip: Trip, tab?: TripsModalTab) => void;
  onSelect: (tripId: string) => void;
  isSelected?: boolean;
  style?: CSSProperties;
};
const TripRow = ({ data: trip, onEditTrip, onSelect, style = undefined }: TripRowProps) => {
  const isSelected = useTripFilters(({ state }) => state.selected.includes(trip.id));

  return (
    <tr
      style={style}
      key={trip?.id}
      onDoubleClick={(): void => onEditTrip(trip)}
      className={getClasses(
        trip.kind,
        trip.type,
        trip.status,
        trip.isLate ? 'LATE' : '',
        trip.state?.completion ? 'COMPLETED' : '',
        trip.deletedAt ? 'DELETED' : '',
        trip.curbsideAt ? 'CURBSIDE' : ''
      )}
    >
      <td style={{ width: '2vw' }}>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={(event): void => {
            event.stopPropagation();
            onSelect(trip?.id);
          }}
        />
      </td>
      <td>
        <Tooltip content="Flags">
          <button onClick={(): void => onEditTrip(trip, 'flag')}>
            <i className="sv sv-flag-empty fs-5" />
          </button>
        </Tooltip>
        <Tooltip content="Communications">
          <button onClick={(): void => onEditTrip(trip, 'communication')}>
            <i className="sv sv-message2 fs-5" />
          </button>
        </Tooltip>
      </td>
      <TooltipCell content={trip?.type || 'Type'}>{trip?.type}</TooltipCell>
      <TooltipCell content={trip?.scheduled || 'Date'}>
        {new Date(trip?.scheduled).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
        })}
      </TooltipCell>
      <TooltipCell content={trip?.scheduled || 'Time'}>
        {new Date(trip?.scheduled).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })}
      </TooltipCell>
      <TooltipCell content="Actual">--</TooltipCell>
      <TooltipCell content={trip?.airportCode || 'City'}>{trip?.airportCode}</TooltipCell>
      <TooltipCell content={trip?.servicerIataAirlineCode || 'Airline'}>{trip?.servicerIataAirlineCode}</TooltipCell>
      <TooltipCell content={trip?.flightNumber || 'Flight'}>{trip?.flightNumber}</TooltipCell>
      <TooltipCell content={trip?.pilots ?? 'Pilots'}>{trip?.pilots}</TooltipCell>
      <TooltipCell content={trip?.attendants ?? 'Attendants'}>{trip?.attendants}</TooltipCell>
      <td style={{ width: '10vw' }}>
        <AssignDriverCell
          rowId={trip.id}
          //title={title}
          title="Assign Driver"
          airportCode={trip.airportCode}
          actual={trip.trackFlight?.actual}
          driver={trip.driver}
          providerId={trip.providerId}
          puLocationId={trip.puLocationId}
          doLocationId={trip.doLocationId}
          combineId={trip.combineId}
          combineType={trip.combineType}
          scheduled={trip.scheduled}
          pilots={trip.pilots}
          attendants={trip.attendants}
          onSetRow={() => {}}
          confirmIllegalCombines={async () => {}}
          //onSetRow={onSetRow}
          //confirmIllegalCombines={confirmIllegalCombines}
        />
      </td>
      <td style={{ width: '5vw' }}>
        <AssignVehicleCell
          actual={trip?.trackFlight?.actual}
          scheduled={trip?.scheduled}
          airportCode={trip?.airportCode}
          driverId={trip?.driverId}
          rowId={trip?.id}
        />
      </td>
      <TooltipCell content={trip?.puLocation?.name || 'Pickup'}>{trip?.puLocation?.name}</TooltipCell>
      <TooltipCell content={trip?.doLocation?.name || 'Dropoff'}>{trip?.doLocation?.name}</TooltipCell>
      <TooltipCell content={trip?.payerProvider?.iataAirlineCode || 'Client'}>{trip?.payerProvider?.iataAirlineCode}</TooltipCell>
      <TooltipCell content="Completion">
        <button onClick={(): void => onEditTrip(trip, 'completion')}>
          <i className="sv sv-completion fs-5" />
        </button>
      </TooltipCell>
      <TooltipCell content="Complaints">
        <button onClick={(): void => onEditTrip(trip, 'fcr')}>
          <i className="sv sv-fcr fs-5" />
        </button>
      </TooltipCell>
      <TooltipCell content="Combine">
        <button onClick={(): void => onEditTrip(trip, 'combine')}>
          <i className="sv sv-combine-icon fs-5 text-secondary" />
        </button>
      </TooltipCell>
      <td>
        <button
          onDoubleClick={(event): void => {
            event?.stopPropagation();
            onEditTrip(trip, 'rate');
          }}
        >
          <RateCell rate={trip.rate?.rate} rowId={trip.id} onSetRow={() => {}} onEditRateReport={() => {}} />
        </button>
      </td>
      <TooltipCell content="Edit">
        <button onClick={(): void => onEditTrip(trip)}>
          <i className="sv sv-pencil" />
        </button>
      </TooltipCell>
    </tr>
  );
};

const TooltipCell = ({ content, children }: { content: React.ReactNode; children: React.ReactNode }) => {
  return (
    <td>
      <Tooltip content={content}>{children}</Tooltip>
    </td>
  );
};

export default TripRow;
