import { DEFAULT_TRIP_SORTING, TripSortColumnEnum } from '@/features/Trips/components/TripsTable/hook';
import { QueryInputType, Validation, queryInput } from '@/utils';
import { SortDirectionEnum, Trip, TripTableFormatEnum, TripTableSearch } from '@/models/gen/graphql';
import TripFilters, {
  TripFiltersRefMethods,
  TripsFiltersState,
  initTripsFiltersState,
  useTripFilters,
} from '@/features/TripsConcept/filters';
import TripsModal, { TripsModalRef, TripsModalTab } from '@/features/TripsConcept/modal';
import { useCallback, useEffect, useRef, useState } from 'react';

import PageInfo from '@/components/PageInfo';
import TripsTable from '@/features/TripsConcept/table';
import { useDeleteTripBulk } from '@/api/services/trips/deleteTripBulk';
import useModal from '@/hooks/useModal';
import { useSearchTripsTable } from '@/api/services/trips/searchTrips';

const TripsConcept = () => {
  // queries
  const [{ data: { rows = [], totalCount = 0 } = {}, loading }, { fetch, refetch }] = useSearchTripsTable();
  const [{ data: { rows: priorityRows = [] } = {}, loading: loadingPriority }, { fetch: fetchPriority, refetch: refetchPriority }] =
    useSearchTripsTable();
  // state
  const [sorting, setSorting] = useState<Array<{ column: TripSortColumnEnum; direction: SortDirectionEnum }>>();
  const [search, setSearch] = useState<string>('');
  const [editingTrip, setEditingTrip] = useState<string | null>(null);
  const [virtualize, setVirtualize] = useState<boolean>(true);
  const [{ loading: loadingDeleteTripBulk }, { fetch: deleteTripBulk }] = useDeleteTripBulk();
  const setTripFiltersState = useTripFilters(({ setState }) => setState);
  const onSubmit = async () => {};
  const [, { show: showEditTripsModal }] = useModal('EditTrips', { onSubmit });
  const [, { show: showEditCompletionModal }] = useModal('EditCompletion', { onSubmit });
  const [, { show: showEditFcrModal }] = useModal('EditFcr', { onSubmit });
  const [, { show: showEditCombineModal }] = useModal('EditCombine', { onSubmit });
  const [, { show: showEditFlagModal }] = useModal('EditFlag', { onSubmit });
  const [, { show: showEditCommunicationModal }] = useModal('EditCommunication', { onSubmit });
  const [, { show: showRateReportModal }] = useModal('RateReport', { onSubmit });

  // refs
  const lastFilters = useRef<TripsFiltersState>(initTripsFiltersState);
  const tripsFiltersRef = useRef<TripFiltersRefMethods>(null);
  const tripsModalRef = useRef<TripsModalRef>(null);
  const selectedRef = useRef<string[]>([]);

  const onFilterSubmit = useCallback(
    async (filters: TripsFiltersState): Promise<void> => {
      const tripSearch = convertTripsFiltersStateToQuery(filters, sorting);
      // fetch priority trips if format is current
      if (filters.format === TripTableFormatEnum.Current) {
        fetchPriority({ query: [tripSearch], format: TripTableFormatEnum.Priority }, { pageSize: 100, page: 0 });
      }
      // fetch trips table
      fetch({ query: [tripSearch], format: filters.format }, { pageSize: 1000, page: 0 });
      lastFilters.current = filters;
    },
    [fetch, fetchPriority, sorting]
  );

  const onReset = async (input: TripsFiltersState): Promise<void> => {
    setSorting(DEFAULT_TRIP_SORTING);
    setSearch(input.search);
    onFilterSubmit(input);
  };
  // FE search
  const onSearch = (val: string): void => setSearch(val);

  const onSelect = (selection: string): void => {
    if (selection === 'all') {
      // deselect all
      if (selectedRef.current.length === rows.length) {
        selectedRef.current = [];
        setTripFiltersState((current) => ({ ...current, selected: [] }));
      } else {
        // select all
        const all = rows.map((trip) => trip.id);
        selectedRef.current = all;
        setTripFiltersState((current) => ({ ...current, selected: all }));
      }
    } else if ((selectedRef.current || []).includes(selection)) {
      // deselect
      const newSelected = (selectedRef.current || []).filter((id) => id !== selection);
      setTripFiltersState((current) => ({ ...current, selected: newSelected }));
      selectedRef.current = newSelected;
    } else {
      // select
      const newSelected = Array.from(new Set([...(selectedRef.current || []), selection]));
      setTripFiltersState((current) => ({ ...current, selected: newSelected }));
      selectedRef.current = newSelected;
    }
  };

  const onDelete = async (): Promise<void> => {
    // update this to either use imperative handle to get 'selected' from filters
    // or pass selected in a way that doesnt cause re-renders
    await deleteTripBulk('', []);
    selectedRef.current = [];
    refetch();
  };

  // set to state refetch on mount
  useEffect((): void => {
    onFilterSubmit(lastFilters.current);
  }, [onFilterSubmit]);

  const showModal = (trip: Trip, tab: TripsModalTab = 'trip'): void => {
    switch (tab) {
      case 'flag':
        showEditFlagModal({
          tripId: trip?.id,
          servicerIataAirlineCode: trip.servicerIataAirlineCode,
          flightNumber: trip.flightNumber.toString().padStart(4, '0'),
          scheduled: trip.scheduled,
        });
        break;
      case 'communication':
        showEditCommunicationModal({
          tripId: trip?.id,
          offset: trip?.offset,
          servicerIataAirlineCode: trip.servicerIataAirlineCode,
          flightNumber: trip.flightNumber.toString().padStart(4, '0'),
          scheduled: trip.scheduled,
        });
        break;
      case 'completion':
        showEditCompletionModal({
          tripId: trip?.id,
          completion: trip?.state?.completion,
          completionId: trip.completionId,
          scheduled: trip.scheduled,
          servicerIataAirlineCode: trip.servicerIataAirlineCode,
          flightNumber: trip.flightNumber.toString().padStart(4, '0'),
        });
        break;
      case 'fcr':
        showEditFcrModal({
          tripId: trip?.id,
          servicerIataAirlineCode: trip.servicerIataAirlineCode,
          flightNumber: trip.flightNumber.toString().padStart(4, '0'),
          scheduled: trip.scheduled,
        });
        break;
      case 'combine':
        showEditCombineModal({ ...trip, tripId: trip?.id });
        break;
      default:
        showEditTripsModal({
          tripId: trip?.id,
          selected: [trip],
          //tripId: selectedTrips ? selectedTrips[0].id : tripId ? tripId : undefined,
          //selected: selectedTrips ? selectedTrips : trip?.id ? [trip] : selected || [],
        });
    }
  };

  return (
    <>
      <PageInfo>
        <div className="d-flex gap-2">
          Total Trips: {rows?.length || 0} / {totalCount}
          <label>
            Virtualize? &nbsp;
            <input type="checkbox" checked={virtualize} onChange={() => setVirtualize(!virtualize)} />
          </label>
        </div>
      </PageInfo>
      <TripFilters
        onSubmit={onFilterSubmit}
        onReset={onReset}
        onSearch={onSearch}
        sorting={sorting}
        ref={tripsFiltersRef}
        onDelete={onDelete}
      />
      <TripsTable
        trips={rows}
        loading={loading}
        onEditTrip={(trip: Trip, tab?: TripsModalTab) => showModal(trip, tab)}
        onSelect={onSelect}
        selected={selectedRef.current}
        virtualize={virtualize}
      />
      <TripsModal ref={tripsModalRef} />
    </>
  );
};

const convertTripsFiltersStateToQuery = (
  filters: TripsFiltersState,
  sorting: Array<{ column: TripSortColumnEnum; direction: SortDirectionEnum }>
): TripTableSearch => {
  // default query
  const query: TripTableSearch = {
    latestScheduled: queryInput.date([filters.from, filters.to]),
  };
  // destructure whatever keys don't map to TripTableSearch
  const { search, format, from, to, rateAmount, ...remainingFilters } = filters || {};
  // loop through rest of the filters
  for (const key in remainingFilters) {
    if (!Validation.isTruthy(remainingFilters[key])) continue;
    // if there is a value, add it to the query
    query[key] = queryInput(remainingFilters[key], QueryInputType.OR);
  }

  // apply sorting
  const output = applySortingToTripQuery(query, sorting);

  return output;
};

const applySortingToTripQuery = (
  query: TripTableSearch,
  sorting: Array<{ column: TripSortColumnEnum; direction: SortDirectionEnum }>
): TripTableSearch => {
  if (!sorting?.length) return query;
  const output = { ...query };

  for (let i = 0; i < sorting.length; i++) {
    const { column, direction } = sorting[i];
    if (!direction) continue;
    const { values = [], type = QueryInputType.DEFAULT } = output[column] || {};
    output[column] = queryInput(values, type, direction, i);
  }

  return output;
};

export default TripsConcept;
