import Tooltip from '@/features/Trips/components/Tooltip';

const FcrCell = ({ count, onClick }: { count: number; onClick: () => void }): React.JSX.Element => (
  <Tooltip content="Complaints">
    <button onClick={onClick}>
      <i className={`sv sv-fcr fs-5 ${count ? 'text-purple' : 'text-primary'}`} />
    </button>
  </Tooltip>
);

export default FcrCell;
